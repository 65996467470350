import { Injectable } from "@angular/core";
import { Style, Fill, Stroke, Circle, Icon, Text } from 'ol/style';
import Map from 'ol/Map';
import Feature from 'ol/Feature';


@Injectable()
export class QualityStyleService{
    constructor(){}

    colors(value){
        let color;
        switch(value.toUpperCase()){
            case 'ALTA':
                color = 'rgba(102,194,165,0.7)'
                break;
            case 'MEDIA':
                color = 'rgba(254,224,139,0.7)';
                break;
            case 'BAJA':
                color = 'rgba(213,62,79,0.7)';
                break;
            default: break;
        }
        return color;
    }

    getQualityStyle(resolution, f: Feature){
        let textValues = '';
        var text = resolution < 15 ? f.get('plotName') : '';
        let value;
        if (f.get('quality')) {
            value = f.get('quality');
            if (value!='') {
                switch(value.toUpperCase()){
                    case 'ALTA':
                        textValues = 'Excellent';
                        break;
                    case 'MEDIA':
                        textValues = 'Good';
                        break;
                    case 'BAJA':
                        textValues = 'Fair';
                        break;
                    default: break;
                }
            }
        }             
        if(textValues!=''){
            if (text == "") {
                text = textValues;
            } else {
                text = text + " | " + textValues;
            }
        }
        if(value){
            return new Style({
                stroke: new Stroke({
                    color: '#000000',
                    width: 1
                }),
                fill: new Fill({
                    color: this.colors(value)
                }),
                text: new Text({
                    text: text,
                    textAlign: 'center',
                    offsetY: 0,
                    scale: 1.1,
                    fill: new Fill({
                        color: '#000000'
                    })
                })
            });
        }else{
            return null;
        }
    }

}
