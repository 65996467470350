import { Injectable } from "@angular/core";
import { Style, Fill, Stroke, Circle, Icon, Text } from 'ol/style';
import Feature from 'ol/Feature';


@Injectable()
export class EviStyleService{
    constructor(){}

    colors(value) {
        let color;
        switch (value) {
            case 0:
                color = 'rgba(158,1,66,255)';
                break;
            case 1:
                color = 'rgba(215,25,28,255)';
                break;
            case 2:
                color = 'rgba(218,40,35,255)';
                break;
            case 3:
                color = 'rgba(222,55,42,255)'
                break;
            case 4:
                color = 'rgba(226,70,49,255)'
                break;
            case 5:
                color = 'rgba(230,86,56,255)'
                break;
            case 6:
                color = 'rgba(234,101,63,255)'
                break;
            case 7:
                color = 'rgba(238,116,70,255)'
                break;
            case 8:
                color = 'rgba(242,131,77,255)'
                break;
            case 9:
                color = 'rgba(246,147,84,255)'
                break;
            case 10:
                color = 'rgba(250,162,91,255)'
                break;
            case 11:
                color = 'rgba(253,176,99,255)'
                break;
            case 12:
                color = 'rgba(253,184,109,255)'
                break;
            case 13:
                color = 'rgba(253,192,118,255)'
                break;
            case 14:
                color = 'rgba(253,201,128,255)'
                break;
            case 15:
                color = 'rgba(253,209,137,255)'
                break;
            case 16:
                color = 'rgba(254,217,147,255)'
                break;
            case 17:
                color = 'rgba(254,225,157,255)'
                break;
            case 18:
                color = 'rgba(254,234,166,255)'
                break;
            case 19:
                color = 'rgba(254,242,176,255)'
                break;
            case 20:
                color = 'rgba(254,250,186,255)'
                break;
            case 21:
                color = 'rgba(250,253,189,255)'
                break;
            case 22:
                color = 'rgba(242,249,186,255)'
                break;
            case 23:
                color = 'rgba(233,246,184,255)'
                break;
            case 24:
                color = 'rgba(224,242,181,255)'
                break;
            case 25:
                color = 'rgba(216,239,178,255)'
                break;
            case 26:
                color = 'rgba(207,235,175,255)'
                break;
            case 27:
                color = 'rgba(199,232,173,255)'
                break;
            case 28:
                color = 'rgba(190,228,170,255)'
                break;
            case 29:
                color = 'rgba(181,225,167,255)'
                break;
            case 30:
                color = 'rgba(173,221,164,255)'
                break;
            case 31:
                color = 'rgba(161,214,165,255)'
                break;
            case 32:
                color = 'rgba(148,204,167,255)'
                break;
            case 33:
                color = 'rgba(134,195,170,255)'
                break;
            case 34:
                color = 'rgba(121,186,172,255)'
                break;
            case 35:
                color = 'rgba(108,177,174,255)'
                break;
            case 36:
                color = 'rgba(95,167,176,255)'
                break;
            case 37:
                color = 'rgba(82,158,179,255)'
                break;
            case 38:
                color = 'rgba(77,156,187,255)'
                break;
            case 39:
                color = 'rgba(56,140,183,255)'
                break;
            case 40:
                color = 'rgba(94,79,162,255)'
                break;
            default:
                color = 'rgba(255,255,255,0)';
                break;
        }
        return color;
    }

   getStyle(value){
        let color = this.colors(value);
        return new Style({
            fill: new Fill({
                color: color
            })
        });
    }

    whichValue(f: Feature) {
        let value = 0;
        if (f.get('a_cat')) {
            value = f.get('a_cat');
        } else if (f.get('cat')) {
            value = f.get('cat');
        } else if (f.get('class')) {
            value = f.get('class');
        } else if (f.get('value')) {
            value = f.get('value');
        } else if (f.get('ID')) {
            value = f.get('ID');
        }
        return value;
    }

    getEviStyle(f: Feature) {
        let value = this.whichValue(f);
        value = Math.round(value * 4);
        return this.getStyle(value);
    }
}
