import { Injectable } from "@angular/core";
import { AuthService, UserModel } from "src/app/modules/auth";
import { Alert } from "./alert.model";
import { first } from "rxjs/operators";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AlertsService {
  // base = 'https://app.e-stratos.eu';
  allNots: any = {};
  allNotsWithoutFilter: any = {};
  plotList = [];
  user: UserModel;

  constructor(private authService: AuthService, public http: HttpClient) {
    this.user = this.authService.currentUserValue;
  }

  sortFunction(a, b) {
    if (a.value === b.value) {
      return 0;
    } else {
      return a.value > b.value ? 1 : -1;
    }
  }

  getAlertsFromServer() {
    return new Promise((resolve, reject) => {
      // console.log("alerts from server")
      // console.log(this.authService.currentUserValue)
      let vlu = {
        user: this.user.id,
        where: 1,
      };
      this.getAlertsFromApi(vlu)
        .pipe(first())
        .subscribe(
          (data) => {
            // console.log("alerts")
            this.allNots = data["data"];
            // console.log(this.allNots)
            let nots = {};
            for (let object of this.allNots) {
              let dateValue: any = new Date(object.description.slice(-11, -1));
              dateValue = dateValue.toLocaleDateString();
              let land = object.description.slice(0, -12);
              land = land.split("-");
              let otherflag = false;
              if (object.type != 1 && object.type != 2 && object.type != 3) {
                otherflag = true;
              }
              if (nots[object.type] == null) {
                if (otherflag) {
                  if (nots["other"] == null) {
                    nots["other"] = [];
                  }
                } else {
                  nots[object.type] = [];
                }
              }
              let url = object.url.split("/");
              if (url[3]) {
                url[3] = url[3].replace("?landid=", "");
                url = `/land/${url[3]}/${url[2]}`;
              } else {
                // no agafa be la url si no te land pk
                url = `${url[1]}`;
              }
              let alert = new Alert();
              alert.pk = object.pk;
              alert.name = object.name;
              alert.date = dateValue;
              alert.url = url;
              alert.field = land[0];
              alert.station = land[1];
              alert.description = object.type;
              if (object.type == 1) {
                alert.value = parseFloat(object.extra);
              } else {
                alert.value = object.extra;
              }
              if (otherflag) {
                nots["other"].push(alert);
              } else {
                nots[object.type].push(alert);
              }
            }
            for (let key in nots) {
              nots[key] = nots[key].sort(this.sortFunction);
            }
            this.allNots = nots;
            resolve(this.allNots);
          },
          (error) => {
            reject(false);
          }
        );
    });
  }

  private getAlertsFromApi(idGroup) {
    // console.log(idGroup)
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.authService.getAuthFromLocalStorage().accessToken,
      }),
    };
    return this.http.get(`${environment.apiUrl}/api/v1/users/user-alerts`, httpOptions).pipe(
      map((result) => {
        return result;
      })
    );
  }

  markOneViewed(id) {
    let notification = {
      id: id,
      user: this.user.id,
    };
    this.setOneAlertViewed(notification)
      .pipe(first())
      .subscribe((result) => {
        if (result["success"] == "yes") {
          console.log("ok");
        }
      });
  }

  private setOneAlertViewed(t) {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.authService.getAuthFromLocalStorage().accessToken,
      }),
    };
    return this.http.get(`${environment.apiUrl}/api/v1/users/user-alerts-markone/${t}`, httpOptions).pipe(
      map((result) => {
        return result;
      })
    );
  }

  markAllAsViewed() {
    var usu = {
      user: this.user.id,
      where: true,
    };
    console.log("all viewed");
    this.setAllAlertsViewed(usu)
      .pipe(first())
      .subscribe((error) => {
        console.log(error);
      });
  }

  private setAllAlertsViewed(t) {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.authService.getAuthFromLocalStorage().accessToken,
      }),
    };
    return this.http.get(`${environment.apiUrl}/api/v1/users/ser-alerts-markall`, httpOptions).pipe(
      map((result) => {
        // console.log(result)
        return result;
      })
    );
  }
}
