import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-graph-tools',
  templateUrl: './graph-tools.component.html',
  styleUrls: ['./graph-tools.component.scss']
})
export class GraphToolsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
