import { EventEmitter, Injectable, Output } from "@angular/core";
import { Style, Fill, Stroke, Circle, Icon, Text } from 'ol/style';
import Map from 'ol/Map';
import Feature from 'ol/Feature';
import { LandsService } from "../../lands/lands.service";
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import View from 'ol/View';
import XYZ from 'ol/source/XYZ';
import * as Proj from 'ol/proj';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import Overlay from 'ol/Overlay';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';
import {transform} from 'ol/proj';
import { LandModel } from "../../lands/land.model";
import GeoJSON from 'ol/format/GeoJSON';
import { MapService } from "../map.service";
import { environment } from "src/environments/environment";
import { Plot } from "../../lands/lands/plots/plot.model";
import { ViewService } from 'src/app/estratos-components/global/view.service';
import { PrintPolygonsService } from 'src/app/estratos-components/map/print-polygons/print-polygons.service';

@Injectable()
export class MapFilesService {
  index = 'ndvi absolute';
  date:Date = new Date();
  plotSelected:Plot;
  landSelected:LandModel;
  firstTime:boolean = true;

  @Output() indexesAndDatesChanged: EventEmitter<any> = new EventEmitter();
  @Output() indexesAndDatesChangedPlot: EventEmitter<any> = new EventEmitter();

  constructor(private landsService: LandsService, private mapService: MapService, private viewService: ViewService, private printPolygonsService: PrintPolygonsService) {
    //Initial
    console.log(this.plotSelected)
    this.mapService.showMapChanged.subscribe(
      b => {
        // console.log('ZZZZZZZZZZZZZZZZZZZZZZ mapService.showMapChanged')
        // console.log(this.firstTime)
        if (this.firstTime && b) {
          //add plot files
          console.log('ZZZZZZZZZZZZZZZZZZZZZZ mapService.showMapChanged')
          this.getIndexesAndDates('showMapChanged').then((result) => {
            // console.log('ZZZZZZZZZZZZZZZZZZZZZZZZZZZ')
            // console.log(result)
            if(result != null){
              this.firstTime = false;
              // console.log(this.landsService.getLandInitial())
              this.fireEmiterIndexAndDates(result,'showMapChanged');
              this.fireEmiterIndexAndDates2(result,'showMapChanged');
              // this.indexesAndDatesChangedPlot.emit(result)
            }
          });
        }
      }
    )
    this.mapService.indexChanged.subscribe(
      index => {
        this.index = index;
        // console.log('mapService.indexChanged')
        this.addFiles(this.date,this.index);
      }
    )
    this.mapService.dateChanged.subscribe(
      date => {
        this.date = date;
        // console.log('mapService.dateChanged')
        this.addFiles(this.date,this.index);
        let showmap = this.mapService.getShowMap();
        // console.log(showmap)
        if(showmap === true){
          this.addFiles(this.date,this.index);
        }
        else{
          this.addFilesAverage(this.date,'average');
        }
      }
    )
    this.landsService.plotSelected.subscribe(
      plot => {
        // console.log("plotSelected mapfiles")
        // console.log(plot)
        // console.log(this.viewService.getView())
        if(plot != undefined && this.viewService.getView() == 'plotDetail'){
          // console.log("plotSelected")
          this.plotSelected = plot;
          this.getIndexesAndDates('plotSelected').then((result) => {
            // console.log('YYYYYYYYYYYYYYYYY')
            // console.log(result)
            if(result != null){
              // console.log(this.mapService.getShowMap())
              let nowshowmap = this.mapService.getShowMap()
              // console.log(nowshowmap)
              if(this.plotSelected && nowshowmap === false){
                // console.log("plot plotSelected")
                // this.firstTime = true;
                this.mapService.setShowMap(true);
              }
              this.firstTime = false;
              // console.log(this.landsService.getLandInitial())
              // this.fireEmiterIndexAndDates(result);
              this.fireEmiterIndexAndDates2(result,'plotSelected');
              // this.indexesAndDatesChangedPlot.emit(result)
            }
          });

        }
        else{
          console.log("NO plotSelected")
        }
      }
    )
    //Coming from landlist
    this.landsService.landSelected.subscribe(
      land => {
        // console.log("XXXXXXXXXXXXXXXXXXXXXXXX land changed")
        // // console.log(land)
        // console.log(this.plotSelected)
        // console.log(this.viewService.getView())
        if(this.viewService.getView() == 'landDetail' || this.viewService.getView() == 'station'){
          if(this.firstTime === true || (this.landSelected && this.landSelected.pk != land.pk) || this.plotSelected){
            this.plotSelected = undefined;
            this.getIndexesAndDates('landSelected').then((result) => {
              // console.log('XXXXXXXXXXXXXXXXXXXXXXXXX')
              // console.log(result)
              if(result != null){
                this.firstTime = false;
                // console.log(this.landsService.getLandInitial())
                this.fireEmiterIndexAndDates(result,'landSelected');
              }
            });
          }
        }
      }
    )
  }

  styleFunction = function (feature) {
    // marc pixel
    // console.log("canta des d'aqui? 1")
    return this.mapService.getStyle(feature);
  }.bind(this);

  addFiles(date=new Date(),index='ndvi absolute') {
    // console.log("lets goooooooooooo")
    // console.log(index)
    let land: LandModel = this.landsService.getLand();
    let map: Map = this.mapService.getMap();
    // remove previous maps
    // console.log(land)
    // console.log(this.plotSelected)
    map.getLayers().forEach(function (layer) {
      if (layer instanceof VectorLayer) {
        if (layer.get('name') == 'plot') {
          map.removeLayer(layer)
        }
      }
    });
    var vectorLayers = [];
    if (land.plots) {
      // console.log("has lands")
      for (let j = 0; j < land.plots.length; j++) {
        let plot = land.plots[j];
        if(this.plotSelected == undefined || this.plotSelected == plot){
          // console.log(plot)
          if(this.firstTime === true){
            this.firstTime = false;
            this.printPolygon(plot);
          }
          if (plot.files) {
            // console.log("has files")
            for (let i = 0; i < plot.files.length; i++) {
              let file = plot.files[i];
              if (file.type == 'JSON' && file.location) {
                let flagIndex = false;
                switch(index){
                  case 'ndvi absolute':
                    if(file.classes == '10' || file.classes == '20' || file.classes == '40'){
                      flagIndex = true;
                    }
                    break;
                  case 'ndvi relative':
                      if(file.classes == '3'){
                        flagIndex = true;
                      }
                      break;
                  case 'ndwi':
                    if(file.classes == 'ndwi'){
                      flagIndex = true;
                    }
                    break;
                  case 'tcari':
                    if(file.classes == 'tcari'){
                      flagIndex = true;
                    }
                    break;
                  case 'evi':
                    if(file.classes == 'evi'){
                      flagIndex = true;
                    }
                    break;
                  case 'pixels':
                    if(file.classes == 'pixels'){
                      // marc pixel
                      console.log("PIXEL!")
                      flagIndex = true;
                    }
                    break;
                  default: break;
                }
                if (flagIndex) {
                  // console.log("has flagInDEX")
                  // console.log(date.toDateString())
                  // console.log((new Date()).toDateString())
                  // console.log(date.getTime())
                  // console.log(file.Date_timeline.getTime())
                  if(date.toDateString() == (new Date()).toDateString() || date.getTime() == file.Date_timeline.getTime()){
                    let url = `${environment.media}${file.location}`;
                    //Files
                    // console.log("File "+url)
                    let geojson_land = new VectorLayer({
                      source: new VectorSource({
                        url: url,
                        format: new GeoJSON({
                          defaultDataProjection: 'EPSG:4326',
                          projection: 'EPSG:3857'
                        })
                      }),
                      style: this.styleFunction
                      /* style: new Style({
                        fill: new Fill({
                          color: 'rgba(255, 255, 255, 0.7)'
                        }),
                        stroke: new Stroke({
                          color: '#000000',
                          width: 2
                        })
                      }) */
                    });
                    geojson_land.set('name', 'plot')
                    geojson_land.set('pk', plot.pk)
                    // geojson_land.set('classes', file.classes)
                    // geojson_land.set('colors', file.colors)
                    vectorLayers.push(geojson_land);
                    map.addLayer(geojson_land)
                    break;
                  }
                }
                // else{
                //   console.log("NO INDEX goooooooooooo")
                //   if(date.toDateString() == (new Date()).toDateString() || date.getTime() == file.Date_timeline.getTime()){
                //     console.log(date.toDateString())
                //     plots_land.push(plot);
                //     break
                //   }
                // }
              }
            }
          }
        }
      }
    }
    // if(plots_land.length > 0){
    //   console.log(plots_land)
    //   this.printPolygonsService.removeLayers().then((result) => {
    //     if(this.viewService.getView() == 'landDetail'){
    //       this.printPolygonsService.printPolygonsOnMap(plots_land,1,'land')
    //       // console.log(arr)
    //       this.mapService.addAverageToLand(plots_land,-1);
    //     }
    //   });
    // }
  }
  addFilesAverage(date=new Date(),index='average') {
    // console.log('Print AVG')
    let land: LandModel = this.landsService.getLand();
    var plots_land = [];
    if (land.plots) {
      this.mapService.addAverageToLand(land.plots,date);
    }

  }

  getIndexesAndDates(where) {
    // console.log('%%%%%%%%%%%%%%%%%%%%%55 getIndexesAndDates')
    // console.log(where)
    return new Promise((resolve, reject) => {
      // console.log("getting indexes")
      let land: LandModel = this.landsService.getLand();
      let land2: LandModel = this.landsService.getLandInitial();
      let theplot: Plot = this.landsService.getPlot();
      this.landSelected = land;
      // console.log(this.landSelected)
      // console.log(this.plotSelected)
      // console.log(theplot)
      if(this.plotSelected == undefined && where != 'landSelected'){
        this.plotSelected = theplot;
      }
      if(land){
        if (land.plots) {
          let is_in = false;
          for (let xx = 0; xx < land.plots.length; xx++) {
            if(this.plotSelected){
              console.log(land.plots[xx]['pk'])
              console.log(this.plotSelected['pk'])
              if(land.plots[xx]['pk'] == this.plotSelected['pk']){
                console.log('is_in')
                is_in = true;
              }
            }
          }
          // console.log(is_in)
          if(is_in == false && this.plotSelected){
            // console.log("WWWWWWWWWWWWWWWWWWWWWplot selected undefined")
            this.plotSelected = undefined;
            land.indexesAndDates = null;
          }
          for (let j = 0; j < land.plots.length; j++) {
            let plot = land.plots[j];
            // console.log("BEF GOTO")
            // console.log(this.plotSelected)
            // // console.log(plot)
            // console.log("BEF GOTO")
            if (this.plotSelected == undefined || this.plotSelected == plot) {
              // console.log("GOTO")
              if (plot.files) {
                // console.log("GOTO2")
                for (let i = 0; i < plot.files.length; i++) {
                  let file = plot.files[i];
                  if (file.type == 'JSON' && file.location) {
                    let index = ''
                    if (file.classes == '10' || file.classes == '20' || file.classes == '40') {
                      // NDVI
                      index = 'ndvi'
                    } else if (file.classes == '3') {
                      // NDVIr
                      index = 'ndviR'
                    } else if (file.classes == 'evi') {
                      // EVI
                      index = 'evi'
                    } else if (file.classes == 'ndwi') {
                      // NDWI
                      index = 'ndwi'
                    } else if (file.classes == 'tcari') {
                      // TCARI
                      index = 'tcari'
                    } else if (file.classes == 'pixels') {
                      // Pixels
                      index = 'pixels'
                    }
                    if (index != '') {
                      if (!land.indexesAndDates) {
                        land.indexesAndDates = {}
                      }
                      if (!land.indexesAndDates[index]) {
                        land.indexesAndDates[index] = []
                      }
                      if (!land.indexesAndDates[index].includes(file.Date_timeline.getTime())) {
                        land.indexesAndDates[index].push(file.Date_timeline.getTime())
                      }
                      // Land full
                      if (!land2.indexesAndDates) {
                        land2.indexesAndDates = {}
                      }
                      if (!land2.indexesAndDates[index]) {
                        land2.indexesAndDates[index] = []
                      }
                      if (!land2.indexesAndDates[index].includes(file.Date_timeline.getTime())) {
                        land2.indexesAndDates[index].push(file.Date_timeline.getTime())
                      }

                    }
                  }
                }
              }
            }
          }
          for (let index in land.indexesAndDates) {
            land.indexesAndDates[index].sort((a, b) => a - b);
            if (index == 'ndvi') {
              if(this.firstTime){
                let date = land.indexesAndDates[index][land.indexesAndDates[index].length - 1];
                this.mapService.setDate(date);
              }else{
                let date = land.indexesAndDates[index][land.indexesAndDates[index].length - 1];
                this.mapService.setDate(date,false);
              }
            }
          }
          // console.log('resolve')
          // console.log(land.indexesAndDates)
          resolve(land.indexesAndDates)
        }else{
          console.log('reject 1')
          resolve(null)
        }
      }else{
        console.log('reject 2')
        resolve(null)
      }

    })
  }
  fireEmiterIndexAndDates(indixi,where){
    // console.log('fireEmiterIndexAndDates')
    // console.log(where)
    // console.log(JSON.stringify(indixi))
    this.indexesAndDatesChanged.emit(indixi)
  }
  fireEmiterIndexAndDates2(indixi,where){
    // console.log('fireEmiterIndexAndDates2')
    // console.log(where)
    // console.log(JSON.stringify(indixi))
    this.indexesAndDatesChangedPlot.emit(indixi)
  }
  printPolygon(plot){
    let map = this.mapService.getMap();
    var vectorSource2 = new VectorSource();
    var vectorSourceS = new VectorSource();
    // plots
    var vectorLayer2 = new VectorLayer({
      source: vectorSource2,
      style: new Style({
          stroke: new Stroke({
              color: '#000000',
              width: 2
          })
      })
    });
    vectorLayer2.set('name','land')
    var where;
    // console.log(plot['coordinates']);
    // console.log(plot['coordinates'][0].coordinates_values);
    let polygon, ring;
    if(!plot['coordinates'][0].includes(']]')){
      ring= JSON.parse('['+plot['coordinates'][0]+']');
      where = ring[0];
      polygon = new Polygon([ring]);
    }else{
      ring= JSON.parse('[['+plot['coordinates'][0]+']]');
      where = ring[0][0];
      polygon = new MultiPolygon([ring]);
    }
    polygon.transform('EPSG:4326', 'EPSG:3857');
    // Create feature with polygon.
    // console.log(ring);
    var feature = new Feature(polygon);
    //console.log(feature)
    //var parcelUrl = plot.pk;
    //var htmlpopup = `<p>Land: <a href="${url}">${lands[i].name}</a></p><p>Plot: <a href="${parcelUrl}?landid=${lands[i].pk}">${plot.name}</a></p>`;
    //feature.set('name', htmlpopup);
    // feature.set('landName', lands[i].name);
    // feature.set('satellite', lands[i].satellite );
    // feature.set('winter', lands[i].winter );
    // feature.set('summer', lands[i].summer );
    // feature.set('active',lands[i].active);
    feature.set("type","plot");
    feature.set("pk_plot", plot.pk);
    feature.set('plotName', plot.name);
    vectorSource2.addFeature(feature);
    map.addLayer(vectorLayer2);
    vectorLayer2.setZIndex(2);
  }
  getInfoIndexes(value,info){

  }
}
