import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ViewService } from 'src/app/estratos-components/global/view.service';
import { MapFilesService } from 'src/app/estratos-components/map/map-files/map-files.service';
import { MapService } from 'src/app/estratos-components/map/map.service';
import { LandsService } from 'src/app/estratos-components/lands/lands.service';

@Component({
  selector: 'app-map-files',
  templateUrl: './map-files.component.html',
  styleUrls: ['./map-files.component.scss']
})
export class MapFilesComponent implements OnInit {
  view:string;
  //show = false;
  //showInit = true;
  //startTimeline = true;

  constructor(private ref: ChangeDetectorRef,private mapService:MapService,private mapFilesService:MapFilesService,private viewService: ViewService,private landsService: LandsService) {
    //this.mapService.setShowMap(this.showInit);
    // console.log('111111111111111111111111 INIT Mapfiles')
    this.view = this.viewService.getView();
    this.viewService.viewChanged.subscribe(
      view => {
        this.view = view;
        this.ref.detectChanges();
      }
    )
  }

  ngOnInit(): void {
  }


}
