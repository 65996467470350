import { EventEmitter, Injectable, Output } from '@angular/core';
import Map from 'ol/Map';
import Layer from 'ol/layer/Layer';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import { Plot } from '../../lands/lands/plots/plot.model';


@Injectable()
export class AverageMapService{
    constructor(){}

    addValuesToMap(map:Map,plots:Plot[],dat){
      // console.log('AverageMapService addValuesToMap')
      // console.log(dat)
        map.getLayers().forEach(function (layer) {
          // console.log(layer)
            if (layer instanceof Layer) {
              // console.log('Layer')
                // searches for layers named as "what"
                // where what == "land" or "plot"
                if (layer.get('name') == 'land') {
                  // console.log('IS Land')
                    let source = new VectorSource();
                    source = layer.getSource();
                    if (source.getFeatures().length > 0) {
                        source.getFeatures().forEach(function (feature) {
                            dance:
                            for(let i=0;i<plots.length;i++){
                                let plot = plots[i];
                                // console.log(plot)
                                if(plot.pk == feature.get("pk_plot")){
                                  // console.log('IS plot')
                                    if(plot.quality && plot.quality != ''){
                                        feature.set('quality',plot.quality);
                                    }
                                    let blank = true;
                                    if(plot.files){
                                      // console.log('Plot files')
                                        if(plot.files.length>0){
                                          // console.log('Plot files2')
                                            for(let j=0;j<plot.files.length;j++){
                                                let file = plot.files[j]
                                                if(file.classes == '10' || file.classes == '3'){
                                                  if(dat == -1){
                                                    // console.log('IS -1')
                                                    feature.set('average',+file.extra_average);
                                                    feature.set('variation',+file.extra_std);
                                                    blank = false;
                                                    break dance;
                                                  }
                                                  else{
                                                    // console.log(dat.getTime())
                                                    // console.log(file.Date_timeline.getTime())
                                                    if(dat.getTime() == file.Date_timeline.getTime()){
                                                      // console.log('IS date')
                                                      feature.set('average',+file.extra_average);
                                                      feature.set('variation',+file.extra_std);
                                                      blank = false;
                                                      break dance;
                                                    }
                                                  }
                                                }
                                            }
                                        }
                                    }
                                    if(blank){
                                      feature.set('average',0);
                                      feature.set('variation',0);
                                      break;
                                    }
                                }

                            }
                        });
                    }
                }
            }
        });
    }
}
