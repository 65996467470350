import { UserModel } from '../../modules/auth';
import { Enterprise } from './enterprises/enterprise.model';
import { WPartInfo } from '../lands/wpart/wpart-info.model';

export class Company {
    pk:string;
    name:string;
    name_legal:string;
    description:string;
    extra_info:string;
    id_holded:string;
    admins:UserModel[];
    is_admin:boolean;
    date_init:Date;
    date_invoice:Date;
    payment_status:string;
    contract_year:number;
    subscription:string;
    not_paid:boolean;
    threemonths:boolean;
    sixmonths:boolean;
    ninemonths:boolean;
    elevenmonths:boolean;
    email_send:boolean;
    categories:string;
    logo:string;
    url:string;
    enterprise:Enterprise;
    active:boolean;
    exportkml:boolean;
    packstress:boolean;
    work_part_on:boolean;
    work_part_info:WPartInfo;
}
