import { Injectable } from '@angular/core';
import Map from 'ol/Map';
import Layer from 'ol/layer/Layer';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import { Style, Fill, Stroke, Circle, Icon } from 'ol/style';


const iconStyleS = new Style({
    image: new Icon({
        opacity: 1,
        src: './assets/media/svg/icons/Map/Marker1.svg',
        offset: [0, 0],
        scale: 1,
    })
});
const visibleStyle = new Style({
    fill: new Fill({
        color: 'rgba(255, 255, 255, 0.7)'
    }),
    stroke: new Stroke({
        color: '#000000',
        width: 2
    }),
    image: new Circle({
        radius: 7,
        fill: new Fill({
            color: '#000000'
        })
    })
});

@Injectable()
export class MapFilter {

    applyFilter(map: Map, what, filters) {
        // iterates each layer from map
        map.getLayers().forEach(function (layer) {
            if (layer instanceof Layer) {
                // searches for layers named as "what"
                // where what == "land" or "plot"
                if (layer.get('name') == what) {
                    let source = new VectorSource();
                    source = layer.getSource();
                    if (source.getFeatures().length > 0) {
                        // iterates each feature
                        source.getFeatures().forEach(function (feature) {
                            let keys = feature.getKeys();
                            let show = true;
                            // if the key from filters is in the feature (exem.: "landName") goes ahead
                            // else jumps to another feature
                            for (let key in filters) {
                                if (keys.includes(key)) {
                                    if (key == 'landName') {
                                        // if filter['landName'] is NOT in the land name 
                                        if (!feature.get(key).includes(filters[key])) {
                                            show = false;
                                        }
                                    } else {
                                        if (feature.get(key) != filters[key]) {
                                            show = false;
                                        }
                                    }
                                } else {
                                    return;
                                }
                            }
                            // if the feature fulfils each filter  
                            if (show) {
                                if(feature.get("type")=="icon"){
                                    feature.setStyle(iconStyleS);
                                }else if(feature.get("type")=="land"){
                                    feature.setStyle(visibleStyle);
                                }
                            } else {
                                feature.setStyle(new Style({}));
                            }
                        });
                    }
                }
            }
        });
    }
}