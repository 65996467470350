import { ChangeDetectorRef, Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { LandModel } from '../../../land.model';
import { WeatherService } from './weather.service';
import { ViewService } from 'src/app/estratos-components/global/view.service';
import { LandsService } from '../../../lands.service';
import { map, catchError, switchMap, finalize, first } from 'rxjs/operators';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit {

  /* @Input() land:LandModel;
 */
  today=null;
  view:string;
  forecast=[];
  constructor(private weatherService: WeatherService, private ref: ChangeDetectorRef, private viewService: ViewService, private landsService: LandsService) {
    this.getWeather();
    this.viewService.viewChanged.subscribe(
      view => {
        this.view = view;
        console.log('viewChanged')
        console.log(view)
        // if()
        // this.wichview = view
        // console.log(view)
        this.ref.detectChanges();
      }
    )
    this.landsService.landSelected.subscribe(
      land => {
        console.log('landSelected WEATHER')
        console.log(land)
        console.log(this.view)
        if(this.view != 'station'){
          this.getWeather();
          this.ref.detectChanges();
        }
      }
    )
  }

  ngOnInit(): void {
  }

  getWeather(){
    // console.log("getting weather")
    // let data = this.weatherService.getWeather()
    // this.today = data['today']
    // this.forecast = data['forecasts']
    let landii = this.landsService.getLand();
    // console.log(landii)
    if(landii){
      const isEmpty = Object.keys(landii).length === 0;
      if(isEmpty != true){
        this.weatherService.getWeather().pipe(first())
            .subscribe(
                data => {
                    // console.log(data)
                    // data = data['data']
                    // this.today = data['today']
                    if(data['status'] == 'ok'){
                      let shhs = [];
                      shhs[0]=data['today'];
                      let shhs2 = this.weatherService.eotFunctionFuture(data['weather_lat'],shhs);
                      this.today = shhs2[0];
                      // this.forecast = data['forecasts']['forecasts']
                      this.forecast = this.weatherService.eotFunctionFuture(data['weather_lat'],data['forecasts']['forecasts'])
                      // console.log(this.forecast)
                      this.ref.detectChanges();
                    }
                },
                error => {
                    console.log('Error')
                    console.log(error)
                }
            );
      }
    }
     /* let datatosend = {
      landid: this.land.pk,
    } */
    /*
    $.post("{% url 'lands:weather_infos_land' 0 %}", datatosend2, function (data){
      console.log("weather?")
      console.log(data)
      let text = ''
      text = `<div class="col-sm-3 more-space" style="padding: 0 20px;">
                <div class="weather-element" style="border-left: 2px solid #d3d6dd;border-right: 2px solid #d3d6dd;padding-top: 10px;">
                    <img src="{% static 'images/' %}${weather_icon_function(data['weather_now']['icon'])}" class="weather-img">
                    <span class="date-weather n-dark-grey">{% trans 'Today' %} ${weather_date_function(data['weather_now']['time'])}</span>
                    <span class="temp-weather n-dark-grey">${parseInt(data['weather_now']['temperature'])}º</span>
                    <span class="time-weather">{% trans 'Rain' %}: ${data['weather_now']['precipProbability']*100} %</span>
                    <span class="time-weather">{% trans 'Wind' %}: ${data['weather_now']['windSpeed']}(${data['weather_now']['windGust']}) m/s</span>
                </div>
            </div>`;
      for(let i=0;i<3;i++){
        // adds lands as rows
        text += `<div class="col-sm-3 more-space">
            <div class="weather-element">
                <img src="{% static 'images/' %}${weather_icon_function(data['weather_5days']['forecasts'][i]['icon'])}" class="weather-img">
                <span class="date-weather n-dark-grey">${weather_date_function(data['weather_5days']['forecasts'][i]['time'])}</span>
                <span class="temp-weather n-dark-grey">${parseInt(data['weather_5days']['forecasts'][i]['temperatureHigh'])} - ${parseInt(data['weather_5days']['forecasts'][i]['temperatureLow'])}º</span>
                <span class="time-weather">{% trans 'Rain' %}: ${data['weather_5days']['forecasts'][i]['precipProbability']*100}%</span>
                <span class="time-weather">{% trans 'Wind' %}: ${data['weather_5days']['forecasts'][i]['windSpeed']}(${data['weather_5days']['forecasts'][i]['windGust']}) m/s</span>
            </div>
        </div>`
      }
      $('#weather_element').append(text);

    }).done(function(){
      // printChart();
    }); */
  }
  weather_icon_function(query) {
    var icon_string = '';
    switch (query) {
      case "clear-day":
        icon_string = './assets/media/svg/icons/Weather/Sun.svg';
        break;
      case "clear-night":
        icon_string = './assets/media/svg/icons/Weather/Sun.svg';
        break;
      case "partly-cloudy-day":
        icon_string = './assets/media/svg/icons/Weather/Cloud-sun.svg';
        break;
      case "partly-cloudy-night":
        icon_string = './assets/media/svg/icons/Weather/Cloud-sun.svg';
        break;
      case "cloudy":
        icon_string = './assets/media/svg/icons/Weather/Cloudy.svg';
        break;
      case "rain":
        icon_string = './assets/media/svg/icons/Weather/Rain1.svg';
        break;
      case "snow":
        icon_string = './assets/media/svg/icons/Weather/Snow1.svg';
        break;
      case "wind":
        icon_string = './assets/media/svg/icons/Weather/Wind.svg';
        break;
      case "fog":
        icon_string = './assets/media/svg/icons/Weather/Fog.svg';
        break;
      case "sleet":
        icon_string = './assets/media/svg/icons/Weather/Rain2.svg';
        break;
      default:
        icon_string = './assets/media/svg/icons/Weather/Cloud-sun.svg';
        break;
    }
    return icon_string;
  }

  hasToday(){
    if(Object.keys(this.today).length>0){
      return true;
    }else{
      return false;
    }
  }
  calcAvgEto(){
    let acum = 0
    for(let i=0;i<this.forecast.length;i++){
      acum+=this.forecast[i]['etotmodv2']
    }
    return acum/this.forecast.length
  }
  getDay(t){
    return new Date(t*1000).getDate()
  }
  getMonth(t){
    return new Date(t*1000).toLocaleString('default', { month: 'long' })
  }

}
