import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { LandModel } from '../../../land.model';
import { LandsService } from '../../../lands.service';

@Component({
  selector: 'app-land-actions',
  templateUrl: './land-actions.component.html',
  styleUrls: ['./land-actions.component.scss']
})
export class LandActionsComponent implements OnInit {

  @Input() land:LandModel;

  constructor(private landsService: LandsService,private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){/* 
    if(this.totalYears == 0){
      let subscription = this.landsService.landSelected.subscribe(
        land => {
          if(land.plots !=undefined && this.totalYears == 0){
            if(land.plots[0].files != undefined){
              this.howManyYears();
              subscription.unsubscribe();
            }            
          }
        }
      )
    } */
  }

  toggleActive(){
    this.changesomething("active",!this.land.active)
  }
  toggleStress(){
    this.changesomething("packStress",!this.land.packStress)
  }
  toggleMidterm(){
    this.changesomething("midSeason",!this.land.midSeason)
  }

  changesomething(what,val){
    this.landsService.changeSomething(what,val).pipe(first())
    .subscribe(
      data => {
        console.log(data)
      },
      error => {
        console.log(error)
      }
    );
  }


}
