import { Injectable } from "@angular/core";
import { Style, Fill, Stroke, Circle, Icon, Text } from 'ol/style';
import Map from 'ol/Map';
import Feature from 'ol/Feature';


@Injectable()
export class NdviStyleService{
    constructor(){}

    colors(flag,value){
        // flag true = absolute, false = relative
        let color;
        switch (true) {
            case (flag && value == 0):
                color = 'rgba(158,1,66,255)';
                break;
            case (flag && value == 1) || (!flag && value == 1):
                color = 'rgba(215,25,28,255)';
                break;
            case (flag && value == 2):
                color = 'rgba(218,40,35,255)';
                break;
            case (flag && value == 3):
                color = 'rgba(222,55,42,255)'
                break;
            case (flag && value == 4):
                color = 'rgba(226,70,49,255)'
                break;
            case (flag && value == 5):
                color = 'rgba(230,86,56,255)'
                break;
            case (flag && value == 6):
                color = 'rgba(234,101,63,255)'
                break;
            case (flag && value == 7):
                color = 'rgba(238,116,70,255)'
                break;
            case (flag && value == 8):
                color = 'rgba(242,131,77,255)'
                break;
            case (flag && value == 9):
                color = 'rgba(246,147,84,255)'
                break;
            case (flag && value == 10):
                color = 'rgba(250,162,91,255)'
                break;
            case (flag && value == 11):
                color = 'rgba(253,176,99,255)'
                break;
            case (flag && value == 12):
                color = 'rgba(253,184,109,255)'
                break;
            case (flag && value == 13):
                color = 'rgba(253,192,118,255)'
                break;
            case (flag && value == 14):
                color = 'rgba(253,201,128,255)'
                break;
            case (flag && value == 15):
                color = 'rgba(253,209,137,255)'
                break;
            case (flag && value == 16):
                color = 'rgba(254,217,147,255)'
                break;
            case (flag && value == 17) || (!flag && value == 2):
                color = 'rgba(254,225,157,255)'
                break;
            case (flag && value == 18):
                color = 'rgba(254,234,166,255)'
                break;
            case (flag && value == 19):
                color = 'rgba(254,242,176,255)'
                break;
            case (flag && value == 20):
                color = 'rgba(254,250,186,255)'
                break;
            case (flag && value == 21):
                color = 'rgba(250,253,189,255)'
                break;
            case (flag && value == 22):
                color = 'rgba(242,249,186,255)'
                break;
            case (flag && value == 23):
                color = 'rgba(233,246,184,255)'
                break;
            case (flag && value == 24):
                color = 'rgba(224,242,181,255)'
                break;
            case (flag && value == 25):
                color = 'rgba(216,239,178,255)'
                break;
            case (flag && value == 26):
                color = 'rgba(207,235,175,255)'
                break;
            case (flag && value == 27):
                color = 'rgba(199,232,173,255)'
                break;
            case (flag && value == 28):
                color = 'rgba(190,228,170,255)'
                break;
            case (flag && value == 29) || (!flag && value == 4):
                color = 'rgba(181,225,167,255)'
                break;
            case (flag && value == 30):
                color = 'rgba(173,221,164,255)'
                break;
            case (flag && value == 31):
                color = 'rgba(161,214,165,255)'
                break;
            case (flag && value == 32):
                color = 'rgba(148,204,167,255)'
                break;
            case (flag && value == 33):
                color = 'rgba(134,195,170,255)'
                break;
            case (flag && value == 34) || (!flag && value == 5):
                color = 'rgba(121,186,172,255)'
                break;
            case (flag && value == 35):
                color = 'rgba(108,177,174,255)'
                break;
            case (flag && value == 36):
                color = 'rgba(95,167,176,255)'
                break;
            case (flag && value == 37):
                color = 'rgba(82,158,179,255)'
                break;
            case (flag && value == 38):
                color = 'rgba(77,156,187,255)'
                break;
            case (flag && value == 39) || (!flag && value == 3):
                color = 'rgba(56,140,183,255)'
                break;
            case (flag && value == 40):
                color = 'rgba(94,79,162,255)'
                break;
            default:
                color = 'rgba(255,255,255,0)';
                break;
        }
        return color;
    }

    colorsNew(flag,value){
        // flag true = absolute, false = relative
        let color;
        switch (true) {
            case (flag && value == 0):
                color = '#7b0d00';
                break;
            case (flag && value == 1) || (!flag && value == 1):
                color = '#7b0d00';
                break;
            case (flag && value == 2):
                color = '#bd2e06';
                break;
            case (flag && value == 3):
                color = '#cf5107'
                break;
            case (flag && value == 4):
                color = '#b66500'
                break;
            case (flag && value == 5):
                color = '#c5a251'
                break;
            case (flag && value == 6):
                color = '#b6b60a'
                break;
            case (flag && value == 7):
                color = '#31bf2f'
                break;
            case (flag && value == 8):
                color = '#52a227'
                break;
            case (flag && value == 9):
                color = '#309604'
                break;
            case (flag && value == 10):
                color = '#006500'
                break;
            default:
                color = 'rgba(255,255,255,0)';
                break;
        }
        return color;
    }
   getStyle(flag,value){
        let color = this.colorsNew(flag,value);
        return new Style({
            fill: new Fill({
                color: color
            })
        });
    }
    getStyleR(flag,value){
         let color = this.colors(flag,value);
         return new Style({
             fill: new Fill({
                 color: color
             })
         });
     }
    whichValue(f:Feature){
        let value = 0;
        if (f.get('a_cat')) {
            value = f.get('a_cat');
        }else if(f.get('cat')){
            value = f.get('cat');
        }else if(f.get('class')){
            value = f.get('class');
        }else if(f.get('value')){
            value = f.get('value');
        }else if(f.get('ID')){
            value = f.get('ID');
        }
        return value;
    }

    getAbsoluteStyle(f: Feature, maxVigourPlot) {
        let value = this.whichValue(f);
        value = Math.round(value * (1 / (Math.round(maxVigourPlot / 2) * 2 / 40)));
        // console.log(value)
        return this.getStyleR(true,value);
    }

    getRelativeStyle(f: Feature){
        let value = this.whichValue(f);
        return this.getStyleR(false,value);
    }

}
