import { EventEmitter, Injectable, Output } from '@angular/core';
import Map from 'ol/Map';
import Layer from 'ol/layer/Layer';
import VectorSource from 'ol/source/Vector';
import { Plot } from '../lands/lands/plots/plot.model';
import { MapCenterView } from './centerView/center-view.service';
import { MapFilter } from './filter/filter.service';
import { ConditionalStyleService } from './styles/conditional-style.service';
import Feature from 'ol/Feature';
import { AverageMapService } from './average-variation/average-map.service';


@Injectable()
export class MapService{
    private map: Map;
    @Output() landsPrinted = new EventEmitter();

    showLand = true;//Poligon Land
    @Output() showLandChanged = new EventEmitter();
    landWhatToShow = 'average';
    @Output() landWhatToShowChanged = new EventEmitter();

    showMap = false;//Index files
    @Output() showMapChanged = new EventEmitter();
    index = 'ndvi absolute';
    @Output() indexChanged = new EventEmitter();
    date:Date;
    @Output() dateChanged = new EventEmitter();

    constructor(private mapFilter: MapFilter, private mapCenterView: MapCenterView, private conditionalStyle: ConditionalStyleService, private averageMapService: AverageMapService){
        window.onresize = () => {
            setTimeout(() => {
                this.resize();
            }, 500);
        }
    }

    getMap(){
        return this.map;
    }
    setMap(newMap: Map){
        this.map = newMap;
    }
    reprintMap(){
      //TODO Change to print allways the land you get or all lands
        console.log("reprinting map")
        this.map.getLayers().forEach(function (layer) {
            if (layer instanceof Layer) {
              console.log(layer.get('name'))
                if (layer.get('name') == 'land' || layer.get('name') == 'plot') {
                    let source = layer.getSource();
                    source.changed();
                }
            }
        });
    }
    applyFilters(what,filters){
        this.mapFilter.applyFilter(this.map,what,filters);
    }
    centerToPlots(plots:Plot[]){
      // console.log(plots)
      // console.log(this.map)
        this.mapCenterView.toPlots(this.map,plots);
    }
    addAverageToLand(plots:Plot[],dat){
      // console.log('addAverageToLand')
      // console.log(plots)
      // console.log(dat)
      this.averageMapService.addValuesToMap(this.map,plots,dat);
    }
    landsOnMap(){
        this.landsPrinted.emit(this.map);
    }
    setShowLand(b){
        this.showLand = b;
        this.showLandChanged.emit(this.showLand);
        if(b && this.showMap){
            this.setShowMap(false);
            // reset to average
            this.changeLandWhatToShow('average')
        }else{
            this.reprintMap();
        }
    }
    setShowPlot(b){
      console.log("setShowPlot")
      console.log(b)
        this.showMap = b;
        this.showMapChanged.emit(this.showMap);
        // if(b && this.showMap){
        //     this.setShowMap(false);
        //     // reset to average
        //     this.changeLandWhatToShow('average')
        // }else{
        //     this.reprintMap();
        // }
    }
    setShowMapInit(){
      // console.log('RRRRRRRRRRRRRRRRRRRRRRRRRRRRRr setShowMapInit')
      this.showMap = false;
      this.showLand = true;
    }
    setShowMap(b){
        console.log("set show map")
        console.log(b)
        console.log(this.showMap)
        this.showMap = b;
        this.showMapChanged.emit(this.showMap);
        if(b && this.showLand){
            this.setShowLand(false);
        }else if(!b && !this.showLand){
            this.setShowLand(true);
        }else{
            this.reprintMap();
        }
    }
    setEverythingOff(){
        this.showMap = false;
        this.showMapChanged.emit(this.showMap);
        this.showLand = false;
        this.showLandChanged.emit(this.showLand);
        this.reprintMap();
    }
    getShowMap(){
        console.log("get show map")
        return this.showMap;
    }
    changeLandWhatToShow(s){
        if(s == 'quality off'){
            // reset to average
            s = 'average'
        }
        this.landWhatToShow = s;
        this.landWhatToShowChanged.emit(this.landWhatToShow);
        this.reprintMap();
    }
    getLandWhatToShow(){
        return this.landWhatToShow;
    }
    getStyle(feature:Feature){
        //showLand:boolean,landWhat:string,showMap:boolean,index:string,feature:Feature
        let resolution = 0;
        if(this.showLand){
            resolution = this.getResolution();
        }
        return this.conditionalStyle.getStyle(this.showLand,this.landWhatToShow,this.showMap,this.index,feature,resolution)
    }
    getStyleForIcon(f:Feature){
        return this.conditionalStyle.getStyleForIcon()
    }
    getStyleAlert(feature:Feature){
        return this.conditionalStyle.getStyleAlert(feature,this.map.getView().getResolution())
    }
    getResolution(){
        return this.map.getView().getResolution();
    }
    changeIndex(i){
        this.setShowMap(true)
        this.index = i;
        this.indexChanged.emit(this.index);
    }
    getIndex(){
        return this.index;
    }
    setDate(d,b=true){
        this.date = new Date(+d);
        console.log(this.date)
        if(b){
            this.dateChanged.emit(this.date);
        }
    }
    getDate(){
        return this.date;
    }
    resize(){
        this.map.updateSize();
    }
}
