import { Alert } from '../alerts/alert.model';
import { Production } from '../productions/production.model';
import { Recomendation } from '../recomendations/recomendation.model';
import { LandFile } from './files/file.model';
import { Issue } from './issues/issue.model';
import { Sensor } from './sensors/sensor.model';

export class Plot {
    pk:string;
    name:string;
    description:string;
    place:string;
    producer:string;
    type:string;
    quality:string;
    map_max_value:number;
    map_min_value:number;
    area:number;
    planet:string;
    opnat:number;
    id_external:string;
    id_user_api:string;
    id_land_api:string;
    points:string;
    active:boolean;
    files:LandFile[];
    sensors:Sensor[];
    recomendations:Recomendation[];
    coordinates:string[];
    issues:Issue[];
    productions:Production[]
    alerts:Alert[]
    states:string
}