import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LandModel } from 'src/app/estratos-components/lands/land.model';
import { LandsService } from 'src/app/estratos-components/lands/lands.service';
import { MapService } from 'src/app/estratos-components/map/map.service';

@Component({
  selector: 'app-select-index',
  templateUrl: './select-index.component.html',
  styleUrls: ['./select-index.component.scss']
})
export class SelectIndexComponent implements OnInit {

  indexesAndDates = {}
  index = '';

  constructor(private landsService: LandsService, private mapService: MapService, private ref: ChangeDetectorRef) {
    let land:LandModel = this.landsService.getLand();
    this.indexesAndDates = land.indexesAndDates;
    this.index = this.mapService.getIndex();
    this.mapService.indexChanged.subscribe(
      index => {
        this.index = index;
        // force ngif to update
        this.ref.detectChanges();
      }
    )
  }

  ngOnInit(): void {
  }

  toggleIndex(i){
    this.mapService.changeIndex(i);
  }

  haveIndex(i){
    if(this.indexesAndDates){
      if(!this.indexesAndDates.hasOwnProperty(i)){
        return true;
      }else{
        return false;
      }
    }
  }
}
