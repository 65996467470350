<!-- <div class="form-group mb-0 row align-items-center" style="padding-right:60px">
    <label class="col-8 col-form-label">Show maps:</label>
    <div class="col-4 d-flex justify-content-end">
      <span class="switch switch-sm switch-primary">
        <label>
          <input type="checkbox" [checked]="show" name="mapFiles" (change)="toggleFiles()" />
          <span></span>
        </label>
      </span>
    </div>
</div> -->
<app-select-index></app-select-index>