import { Injectable } from "@angular/core";
import { Style, Fill, Stroke, Circle, Icon ,Text} from 'ol/style';
import Map from 'ol/Map';
import Feature from 'ol/Feature';
import { ViewService } from "../../global/view.service";
import { LandsService } from "../../lands/lands.service";
import { AverageVariationStyleService } from "./land/average-variation.service";
import { QualityStyleService } from "./land/quality.service";
import { NdviStyleService } from "./indexes/ndvi.service";
import { EviStyleService } from "./indexes/evi.service";
import { NdwiStyleService } from "./indexes/ndwi.service";
import { TcariStyleService } from "./indexes/tcari.service";
import { DotStyleService } from "./land/dot-style.service";
import { PixelsStyleService } from "./indexes/pixels.service";

@Injectable()
export class ConditionalStyleService{

    emptystyle = null;
    constructor(private viewService:ViewService, private landsService:LandsService, private dotStyleService: DotStyleService, private averageVariationStyleService: AverageVariationStyleService, private qualityStyleService: QualityStyleService, private ndviStyleService: NdviStyleService, private eviStyleService: EviStyleService, private ndwiStyleService: NdwiStyleService, private tcariStyleService: TcariStyleService, private pixelsStyleService: PixelsStyleService){}
    getStyle(showLand:boolean,landWhat:string,showMap:boolean,index:string,feature:Feature,resolution){
        let result = null;
        // console.log('showLand')
        // console.log(showMap)
        // console.log(landWhat)
        // console.log(this.viewService.getView())
        // console.log(feature.get("a_cat"))
        if((this.viewService.getView() == 'landDetail' && feature.get("type") == 'land') || this.viewService.getView() == 'station'){
          // console.log('land')
          // console.log(showLand)
          // console.log(showMap)
          let land = this.landsService.getLand();
          // console.log(land)
          if(land){
            // console.log(feature.get("landName"))
            if(showLand &&  land.name == feature.get("landName")){
              if(resolution>15){
                result = this.dotStyleService.getDotStyle(landWhat,feature)
              }else{
                // console.log(landWhat)
                if(landWhat=='average'){
                  result = this.averageVariationStyleService.getAverageStyle(resolution,feature)
                }else if(landWhat=='variation'){
                  result = this.averageVariationStyleService.getVariationStyle(resolution,feature)
                }else if(landWhat=='quality'){
                  result = this.qualityStyleService.getQualityStyle(resolution,feature)
                }
              }
            }
          }
        }else if((this.viewService.getView() == 'land' || this.viewService.getView() == 'client' || this.viewService.getView() == 'landPart' || this.viewService.getView() == 'workPart') && feature.get("type") == 'land'){
          // console.log('land2')
            result = new Style({
                stroke: new Stroke({
                    color: '#000000',
                    width: 1
                }),
                fill: new Fill({
                    color: 'rgba(255,255,255,0.7)'
                })
            });
        }else if(showMap && feature.get("a_cat") && (this.viewService.getView() == 'plotDetail' || this.viewService.getView() == 'landDetail' || this.viewService.getView() == 'station')){
          // console.log('showmap -----')
          // console.log(index)
            switch (index) {
                case 'ndvi absolute':
                    var maxVigourPlot = 10;
                    if(this.viewService.getView() == 'plotDetail'){
                        // get maxvigour from plot
                        //return result;
                    }
                    result = this.ndviStyleService.getAbsoluteStyle(feature,maxVigourPlot);
                    break;
                case 'ndvi relative':
                    result = this.ndviStyleService.getRelativeStyle(feature);
                    break;
                case 'ndwi':
                    result = this.ndwiStyleService.getNdwiStyle(feature);
                    break;
                case 'tcari':
                    result = this.tcariStyleService.getTcariStyle(feature);
                    break;
                case 'evi':
                    result = this.eviStyleService.getEviStyle(feature);
                    break;
                case 'pixels':
                    var maxVigourPlot = 10;
                    result = this.pixelsStyleService.getPixelsStyle(feature, maxVigourPlot);
                    break;
                default:
                    break;
            }
        }
        return result;
    }
    getStyleForIcon(){
      if(this.viewService.getView() == 'land' ){
        return new Style({
          image: new Icon({
            opacity: 1,
            src: './assets/media/svg/icons/Map/Marker1.svg',
            //size: [100, 100],
            offset: [0, 0],
            scale: 1,
            //color: [163, 103, 82, 1]
          })
        });
      }else{
        return null
      }
    }
    getStyleAlert(feature:Feature,resolution){
      // console.log(feature.get("active"))
      // console.log(resolution)
      let result = null;
      let colorBot = 'rgb(255, 0, 0)';
      let phrase = feature.get('name')+' ['+feature.get('waypoint')+']';
      phrase = this.stringDivider(phrase, 16, '\n');
      let text = resolution < 20 ? phrase : '';
      // let text = phrase;
      if(feature.get('active') === false){
          colorBot = 'rgb(0, 0, 0)';
        }
      result = new Style({
          image: new Circle({
            fill:new Fill({
              color: colorBot
            }),
            stroke:new Stroke({
              width: 1,
              color: 'rgb(0, 0, 0)'
            }),
            radius:5
          }),
          text: new Text({
            text: text,
            textAlign: 'center',
            offsetY: -15,
            scale: 1.3,
            fill:new Fill({
              color: '#fff'
            }),
            stroke:new Stroke({
              color: '#000',
              width: 1
            })
          }),
      });
      return result
    }
    stringDivider(str, width, spaceReplacer) {
        if (str.length > width) {
          var p = width;
          while (p > 0 && (str[p] != ' ' && str[p] != '-')) {
            p--;
          }
          if (p > 0) {
            var left;
            if (str.substring(p, p + 1) == '-') {
              left = str.substring(0, p + 1);
            } else {
              left = str.substring(0, p);
            }
            var right = str.substring(p + 1);
            return left + spaceReplacer + this.stringDivider(right, width, spaceReplacer);
          }
        }
        return str;
      }
}
