<table class="table table-striped">
    <tbody>
        <tr *ngIf="today" class="bold">
            <td>Today<br/>{{getDay(today.time)}} {{getMonth(today.time)}}</td>
            <td><img src="{{weather_icon_function(today.icon)}}"></td>
            <td>{{today.temperatureHigh}}º<br/>{{today.temperatureLow}}º</td>
            <td>Rain<br/>{{today.precipProbability | number : '1.3-3'}}%</td>
            <td>Wind<br/>{{today.windSpeed | number : '1.3-3'}}m/s</td>
            <td>ETo<br/>{{today.etotmodv2 | number : '1.3-3'}}mm/day</td>
            <td>VPD<br/>{{today.vpd | number : '1.3-3'}}kPa/day</td>
        </tr>
    <tr *ngIf="forecast.length > 0" class="bold">
        <td colspan="5">Average ETo forecast for the next 6 days</td>
        <td>{{calcAvgEto() | number : '1.3-3'}}</td>
    </tr>
    <tr *ngFor="let day of forecast">
        <td>{{getDay(day.time)}} {{getMonth(day.time)}}</td>
        <td><img src="{{weather_icon_function(day.icon)}}"></td>
        <td>{{day.temperatureHigh}}º<br/>{{day.temperatureLow}}º</td>
        <td>Rain<br/>{{day.precipProbability  | number : '1.3-3'}}%</td>
        <td>Wind<br/>{{day.windSpeed  | number : '1.3-3'}}m/s</td>
        <td>ETo<br/>{{day.etotmodv2  | number : '1.3-3'}}mm/day</td>
        <td>VPD<br/>{{day.vpd  | number : '1.3-3'}}kPa/day</td>
    </tr>
    </tbody>


</table>
