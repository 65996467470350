<!-- <div class="form-group mb-0 row align-items-center" *ngIf="show">
    <label class="col-8 col-form-label" *ngIf="averageOrVariation == 'average'">Average NDVI:</label>
    <label class="col-8 col-form-label" *ngIf="averageOrVariation == 'variation'">C. Variation:</label>
    <div class="col-4 d-flex justify-content-end">
      <span class="switch switch-sm switch-primary">
        <label>
          <input type="checkbox" [checked]="averageOrVariation == 'average'" name="averageOrVariation" (change)="toggleAvgVar()" />
          <span></span>
        </label>
      </span>
    </div>
</div> -->
<div class="btn-group btn-group-sm" role="group" style="width: 100%; margin-bottom: 5px;" *ngIf="show">
  <button type="button" class="btn btn-outline-primary" [class.active]="averageOrVariation == 'average'"
      (click)="toggleAvgVar()" style="padding: 5px 0px; flex-basis: 0%; border-right: 3px;">Average NDVI</button>
  <button type="button" class="btn btn-outline-primary" [class.active]="averageOrVariation == 'variation'"
      (click)="toggleAvgVar()" style="padding: 5px 0px; flex-basis: 0%;">C. Variation</button>
</div>