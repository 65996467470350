import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GraphAnalyticsService {

  constructor() { }

  cleanValues(val){
    let final = [];
    let avgs = [];
    let cvs = [];
    for(let i=0;i<val.length;i++){
      let file = val[i];
      // console.log(file['Date_timeline'])
      if(file['classes'] == '3' && file['Date_timeline']){
        let dateAverage2 = new Date(file['Date_timeline']);
        // console.log(dateAverage2)
        let year = 0;
        year = dateAverage2.getFullYear();
        // console.log(dateAverage2.getFullYear())
        let dateAverage = 0;
        dateAverage = dateAverage2.getTime();
        avgs.push([dateAverage, parseFloat((+file['extra_average']).toFixed(3)), 0, file['pk']]);
        cvs.push([dateAverage, +(+file['extra_std'] /+file['extra_average']).toFixed(3)]);
      }
    }
    final.push(avgs)
    final.push(cvs)
    // console.log(final_1)
    for(let j=0;j<final[0].length;j++){
      //console.log(values2017[i]);
      if(j==0){
        final[0][j][2]=0;
      }else{
        final[0][j][2] = Math.floor(((final[0][j][1]-final[0][j-1][1])/final[0][j-1][1])*100);
      }
    }
    let first_file=new Date().getTime()
    let last_file=new Date(0).getTime()
    for(let k=0;k<final[0].length;k++){
      let date = final[0][k][0]
      if(date < first_file){
        first_file = final[0][k][0]
      }
      if(date > last_file){
        last_file = final[0][k][0]
      }
    }
    return final;
  }
}
