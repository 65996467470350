import { Component, OnInit } from '@angular/core';
import { LandModel } from '../land.model';
import { LandsService } from '../lands.service';
import { map, catchError, switchMap, finalize, first, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-lands',
  templateUrl: './lands.component.html',
  styleUrls: ['./lands.component.scss']
})
export class LandsComponent implements OnInit {

  lands:LandModel[] = []
  constructor(private landsService: LandsService) {
    this.landsService.landsModified.subscribe(
      lands => {
        this.lands = lands;
      }
    )
  }

  ngOnInit(): void {
    this.getLands()
  }

  getLands() {
    // let lands = this.landsService.requestLands();
    // console.log(lands)
    this.landsService.requestLands().pipe(first())
      .subscribe(
        data => {
          let res = data;
          //console.log(res)
          // get plots
          this.landsService.requestPlotsOverview().pipe(first())
            .subscribe(
              data => {
                let res = data;
              },
              error => {
              }
            );
        },
        error => {
          //console.log(error)
        }
      );
  }



}
