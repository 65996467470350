import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/modules/auth";
import { environment } from "src/environments/environment";
import { LandModel } from "../../../land.model";

@Injectable({
  providedIn: 'root'
})
export class LandActionsService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  saveFileNewLandActions(file){
      let token = this.authService.getAuthFromLocalStorage().accessToken;
      let data = {
          token: token,
          file_obj:file
      };
      let httpOptions2 = {
          headers: new HttpHeaders({'Authorization':`Bearer ${token}`})
      };
      // file.append('token', token);
      // let httpOptions2 = new HttpHeaders();
      // httpOptions2.append('Content-Type', 'multipart/form-data');
      // httpOptions2.append('Accept', 'application/json');
      // httpOptions2.append('Authorization', `Bearer ${token}`);
      return this.http.post(`${environment.apiUrl}/api/v1/lands/new-file-create-land`, file, httpOptions2).pipe(map(result => {
          if(!result['error']){
              // grab new plot data and adds it to lands
              console.log("OK")
              return result
          }else{
              console.log("ERROR")
              return false
          }
      }));
  }
  getFileFromUrlLandActions(url){
    let httpOptions2 = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };
    return this.http.get(`${environment.mediaSCW}`+url, httpOptions2).pipe(map(result => {
      if(!result['error']){
          // grab new plot data and adds it to lands
          console.log("OK")
          if(url.includes('.geojson')){
            return result
          }
          else{
            return false
          }

      }else{
          console.log("ERROR")
          return false
      }
    }));
  }
  createLandActions(land,urlfile,whe,fies){
      let token = this.authService.getAuthFromLocalStorage().accessToken;
      let data = {
          // token: token,
          land_form:land,
          url_file:urlfile,
          where:whe,
          fields:fies
      };
      let httpOptions2 = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':`Bearer ${token}`})
      };
      return this.http.post(`${environment.apiUrl}/api/v1/lands/new-land`, data, httpOptions2).pipe(map(result => {
        if(!result['error']){
            // grab new plot data and adds it to lands
            console.log("OK")
            return result
        }else{
            console.log("ERROR")
            return false
        }
      }));
  }
}
