import { Component, OnInit } from '@angular/core';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { MapService } from 'src/app/estratos-components/map/map.service';

@Component({
  selector: 'app-street-map',
  templateUrl: './street-map.component.html',
  styleUrls: ['./street-map.component.scss']
})
export class StreetMapComponent implements OnInit {

  map: Map;
  street: TileLayer;
  show:boolean=false;

  constructor(private mapService:MapService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.map = this.mapService.getMap();
    this.street=new TileLayer({
      opacity: 0,
      source: new XYZ({
        url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoiam9zZXByZW4iLCJhIjoiY2l4dDJhamdmMDAwcjJwdGc1dzd5ZzhvcyJ9._nZdLJgfTXzszURzLAPZvw',
        crossOrigin: 'anonymous'
      })
    });
    this.map.addLayer(this.street);
  }

  toggleStreetMap(){
    if(!this.show){
      this.street.setOpacity(0.5);
    }else{
      this.street.setOpacity(0);
    }
    this.show = !this.show;
  }

}
