import { Component, OnInit } from '@angular/core';
import { LandsService } from 'src/app/estratos-components/lands/lands.service';
import { MapService } from 'src/app/estratos-components/map/map.service';
import { EviStyleService } from 'src/app/estratos-components/map/styles/indexes/evi.service';
import { NdviStyleService } from 'src/app/estratos-components/map/styles/indexes/ndvi.service';
import { NdwiStyleService } from 'src/app/estratos-components/map/styles/indexes/ndwi.service';
import { PixelsStyleService } from 'src/app/estratos-components/map/styles/indexes/pixels.service';
import { TcariStyleService } from 'src/app/estratos-components/map/styles/indexes/tcari.service';
import { AverageVariationStyleService } from 'src/app/estratos-components/map/styles/land/average-variation.service';
import { QualityStyleService } from 'src/app/estratos-components/map/styles/land/quality.service';

@Component({
  selector: 'app-map-legend',
  templateUrl: './map-legend.component.html',
  styleUrls: ['./map-legend.component.scss']
})
export class MapLegendComponent implements OnInit {

  legend = []
  showLand = true;
  landWhatToShow = 'average';
  showMap = false;
  index = 'ndvi absolute';
  maxVigourPlot = 10;
  quantiles;

  constructor(private mapService: MapService, private averageVariationStyleService: AverageVariationStyleService, private qualityStyleService: QualityStyleService, private landsService: LandsService, private ndviStyleService:NdviStyleService, private eviStyleService: EviStyleService, private ndwiStyleService: NdwiStyleService, private tcariStyleService: TcariStyleService, private pixelsStyleService: PixelsStyleService) {
    this.mapService.showLandChanged.subscribe(
      b => {
        this.showLand = b;
        this.whichLegend()
      }
    )
    this.mapService.landWhatToShowChanged.subscribe(
      what => {
        this.landWhatToShow = what;
        this.whichLegend()
      }
    )
    this.mapService.showMapChanged.subscribe(
      b => {
        this.showMap = b;
        this.whichLegend()
      }
    )
    this.mapService.indexChanged.subscribe(
      index => {
        this.index = index;
        this.whichLegend()
      }
    )
  }


  ngOnInit(): void {
    this.whichLegend()
  }

  whichLegend(){
    this.legend = [];
    let values = [];
    if(this.showLand){
      switch(this.landWhatToShow){
        case 'average':
          values = ['nv',0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1]
          this.legend.push({
            minValue:'',
            maxValue:values[0],
            color: '#fff',
            textColor: '#000'
          });
          for(let i=1;i<values.length-1;i++){
            let color = this.averageVariationStyleService.colors(true,values[i]);
            this.legend.push({
              minValue:values[i],
              maxValue:values[i+1],
              color: color,
              textColor: this.averageVariationStyleService.blackorwhite(color)
            });
          }
          break;
        case 'variation':
          values = ['nv',0,5,15,25]
          this.legend.push({
            minValue:'',
            maxValue:values[0],
            color: '#fff',
            textColor: '#000'
          });
          for(let i=1;i<values.length-1;i++){
            let color = this.averageVariationStyleService.colors(false,values[i+1]);
            this.legend.push({
              minValue:values[i]+'%',
              maxValue:values[i+1]+'%',
              color: color,
              textColor: this.averageVariationStyleService.blackorwhite(color)
            });
          }
          let lastColor = this.averageVariationStyleService.colors(false,values[values.length-1]+1);
          this.legend.push({
            minValue:'',
            maxValue:'>'+values[values.length-1]+'%',
            color: lastColor,
            textColor: this.averageVariationStyleService.blackorwhite(lastColor)
          });
          break;
        case 'quality':
          values = ["Alta","Media","Baja"]
          for(let i=0;i<values.length;i++){
            let color = this.qualityStyleService.colors(values[i]);
            this.legend.push({
              minValue:'',
              maxValue:values[i],
              color: color,
              textColor: this.averageVariationStyleService.blackorwhite(color)
            });
          }
          break;
        default: break;
      }
    }else if(this.showMap){
      switch(this.index){
        case 'ndvi absolute':
          this.maxVigourPlot = this.landsService.getMaxVigourPlot();
          for (let i = 0; i < 10; i++) {
            let colorFinder = Math.round((i+1) * (1 / (Math.round(this.maxVigourPlot / 2) * 2 / 40)))
            let color = this.ndviStyleService.colors(true,colorFinder);
            this.legend.push({
              minValue:((i) * (this.maxVigourPlot / 20) * 2 / 10).toFixed(2),
              maxValue:((i + 1) * (this.maxVigourPlot / 20) * 2 / 10).toFixed(2),
              color:color,
              textColor:this.averageVariationStyleService.blackorwhite(color)
            })
          }
          break;
        case 'ndvi relative':
          this.quantiles = this.landsService.getQuantileFiles();
          for (let i = 1; i <= 3; i++) {
            let color = this.ndviStyleService.colors(false,i);
            let min='', max='';
            if(this.quantiles != null){
              min=this.quantiles;
              max=this.quantiles;
            }
            this.legend.push({
              minValue:min,
              maxValue:max,
              color:color,
              textColor:this.averageVariationStyleService.blackorwhite(color)
            })
          }
          break;
        case 'ndwi':
          this.quantiles = this.landsService.getQuantileFiles();
          for (let i = 1; i <= 3; i++) {
            let color = this.ndwiStyleService.colors(i);
            let min='', max='';
            if(this.quantiles != null){
              min=this.quantiles;
              max=this.quantiles;
            }
            this.legend.push({
              minValue:min,
              maxValue:max,
              color:color,
              textColor:this.averageVariationStyleService.blackorwhite(color)
            })
          }
          break;
        case 'tcari':
          for (let i = 1; i <= 3; i++) {
            let color = this.tcariStyleService.colors(i);
            let min='', max='';
            if(this.quantiles != null){
              min=this.quantiles;
              max=this.quantiles;
            }
            this.legend.push({
              minValue:min,
              maxValue:max,
              color:color,
              textColor:this.averageVariationStyleService.blackorwhite(color)
            })
          }
          break;
        case 'evi':
          this.maxVigourPlot = this.landsService.getMaxVigourPlot();
          for (let i = 0; i < 10; i++) {
            let colorFinder = Math.round((i+1) * (1 / (Math.round(this.maxVigourPlot / 2) * 2 / 40)))
            let color = this.eviStyleService.colors(colorFinder);
            this.legend.push({
              minValue:((i) * (this.maxVigourPlot / 20) * 2 / 10),
              maxValue:((i + 1) * (this.maxVigourPlot / 20) * 2 / 10),
              color:color,
              textColor:this.averageVariationStyleService.blackorwhite(color)
            })
          }
          break;
        case 'pixels':
          // marc pixel
          this.maxVigourPlot = this.landsService.getMaxVigourPlot();
          for (let i = 0; i < 10; i++) {
            let colorFinder = Math.round((i+1) * (1 / (Math.round(this.maxVigourPlot / 2) * 2 / 40)))
            let color = this.pixelsStyleService.colors(true,colorFinder);
            this.legend.push({
              minValue:((i) * (this.maxVigourPlot / 20) * 2 / 10).toFixed(2),
              maxValue:((i + 1) * (this.maxVigourPlot / 20) * 2 / 10).toFixed(2),
              color:color,
              textColor:this.averageVariationStyleService.blackorwhite(color)
            })
          }
          break;
        default: break;
      }
    }
    // console.log("legend color")
    // console.log(this.legend)
  }

  getStyle(i){
    if(i.color == '#fff'){
      return {"background-color":i.color,"border":"0.1px solid black","color":i.textColor}
    }else{
      return {"background-color":i.color,"color":i.textColor}
    }

  }

}
