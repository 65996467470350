import { Injectable } from '@angular/core';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import { getCenter } from 'ol/extent';
import { AverageVariationStyleService } from './average-variation.service';
import { QualityStyleService } from './quality.service';



@Injectable({
  providedIn: 'root'
})
export class DotStyleService {

  constructor(private averageVariationStyleService: AverageVariationStyleService, private qualityStyleService: QualityStyleService) { }

  getDotStyle(what, f: Feature) {
    let color = 'rgba(255,255,255,0.7)';
    if (what == 'quality') {
      let value;
      if (f.get('quality')) {
        value = f.get('quality');
      }
      if (value) {
        color = this.qualityStyleService.colors(value)
      }
    } else {
      let value = 0;
      let flag = true;
      if (f.get('average')) {
        value = f.get('average');
      }
      if (what == 'variation') {
        if (f.get('average') && f.get('variation')) {
          flag = false;
          let variation = f.get('variation');
          if (variation >= 0) {
            value = (variation / value) * 100
          }
        }
      }
      color = this.averageVariationStyleService.colors(flag, value)
    }
    let styles = [
      new Style({
        stroke: new Stroke({
          color: 'rgba(255,255,255,0)',
          width: 1
        }),
        fill: new Fill({
          color: 'rgba(255,255,255,0)'
        })
      }),
      new Style({
        image: new Circle({
          radius: 5,
          fill: new Fill({
            color: color,
          }),
          stroke: new Stroke({
            color: 'rgba(0,0,0,1)',
            width: 1
          })
        }),
        geometry: function (f) {
          const coordinates = getCenter(f.getGeometry().getExtent());
          return new Point(coordinates);
        }
      })
    ];
    return styles;
  }
}
