import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, of, Subscription } from "rxjs";
import { map, catchError, switchMap, finalize } from "rxjs/operators";
import { UserModel } from "../_models/user.model";
import { AuthModel } from "../_models/auth.model";
import { AuthHTTPService } from "./auth-http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(private authHttpService: AuthHTTPService, private router: Router) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((res) => {
        console.log(res);
        if (res.status == "error") {
          console.log("Error");
          return false;
        } else {
          let date = new Date();
          date.setDate(date.getDate() + 7);
          let auth = new AuthModel();
          // auth.accessToken = res.token;
          // auth.refreshToken = res.token;
          auth.accessToken = res.access_token;
          auth.refreshToken = res.access_token;
          auth.expiresIn = date;
          const result = this.setAuthFromLocalStorage(auth);
          return result;
        }
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error("err", err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(["/auth/login"], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.accessToken).pipe(
      map((user) => {
        let fullUser = new UserModel();
        if (user) {
          // user = JSON.parse(user);
          user = user.user;
          console.log(user);
          fullUser.id = user.fields.pk;
          fullUser.username = user.fields.username;
          fullUser.password = user.fields.password;
          fullUser.fullname = user.fields.name;
          fullUser.email = user.fields.email;
          fullUser.pic = `${user.fields.avatar}`;
          fullUser.wp_admin = user.fields.wp_admin;
          fullUser.wp_superadmin = user.fields.wp_superadmin;
          // fullUser.pic = `${user.fields.avatar}`;
          let roles = [];
          if (user.fields.is_staff == true) {
            roles.push(1);
          }
          if (user.fields.is_superuser == true) {
            roles.push(2);
          } else {
            roles.push(3);
          }
          fullUser.roles = roles;
          fullUser.occupation = user.fields.user_extra_plots;
          fullUser.companyName = JSON.stringify(user.fields.user_companies);
          fullUser.phone = user.fields.phone;
          fullUser.firstname = user.fields.name;
          fullUser.lastname = user.fields.first_name;
          fullUser.website = "";
          fullUser.language = "en";
          fullUser.timeZone = "Madrid";
          fullUser.communication = {
            email: user.fields.send_email,
            sms: false,
            phone: false,
          };
          fullUser.emailSettings = {
            emailNotification: user.fields.send_email,
            sendCopyToPersonalEmail: false,
            activityRelatesEmail: {
              youHaveNewNotifications: user.fields.send_email,
              youAreSentADirectMessage: false,
              someoneAddsYouAsAsAConnection: false,
              uponNewOrder: false,
              newMembershipApproval: false,
              memberRegistration: false,
            },
            updatesFromKeenthemes: {
              newsAboutKeenthemesProductsAndFeatureUpdates: false,
              tipsOnGettingMoreOutOfKeen: false,
              thingsYouMissedSindeYouLastLoggedIntoKeen: false,
              newsAboutMetronicOnPartnerProductsAndOtherServices: false,
              tipsOnMetronicBusinessProducts: false,
            },
          };
          this.currentUserSubject = new BehaviorSubject<UserModel>(fullUser);
          console.log(fullUser);
        } else {
          this.logout();
        }
        return fullUser;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // need create new user then login
  // JOSEP: PENDENT DE MODIFICAR PER CREAR USUARIS
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.log("error aqui");
        console.error("err", err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.forgotPassword(email).pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.accessToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken));
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
