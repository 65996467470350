import { Component, OnInit } from '@angular/core';
import Map from 'ol/Map';
import Layer from 'ol/layer/Layer';
import XYZ from 'ol/source/XYZ';
import { MapService } from 'src/app/estratos-components/map/map.service';

@Component({
  selector: 'app-toggle-plots',
  templateUrl: './toggle-plots.component.html',
  styleUrls: ['./toggle-plots.component.scss']
})
export class TogglePlotsComponent implements OnInit {

  /* map: Map;
  layers: Layer[] = []; */
  show:boolean=true;

  constructor(private mapService:MapService) {
    /* let subscription = this.mapService.landsPrinted.subscribe(
      map => {
        this.map = map;
        this.layers = this.getLayers();
        subscription.unsubscribe();
      }
    ) */
    this.mapService.showLandChanged.subscribe(
      b => { 
        this.show = b;        
      }
    )
  }

  ngOnInit(): void {
  }

  /* getLayers(){
    let a = []
    this.map.getLayers().forEach(function (layer) {
      if (layer instanceof Layer) {
        if (layer.get('name') == "land") {
          a.push(layer)
        }
      }
    });
    return a;
  } */

  togglePlots(){
    /* if(this.layers.length==0){
      this.map = this.mapService.getMap();
      this.layers = this.getLayers()
    }
    for(let i=0;i<this.layers.length;i++){
      let layer = this.layers[i];
      if(!this.show){      
        layer.setOpacity(1);
      }else{
        layer.setOpacity(0);
      }
    } */
    this.mapService.setShowLand(!this.show);
  }

}
