import { Injectable, ElementRef } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelExportService {

  constructor() { }

  /**
   * Creates excel from the table element reference.
   * https://dev.to/idrisrampurawala/exporting-data-to-excel-and-csv-in-angular-3643
   * @param element DOM table element reference.
   * @param fileName filename to save as.
   */
  public exportTableElmToExcel(element: ElementRef, fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element.nativeElement);
    // generate workbook and add the worksheet
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    // save to file
    XLSX.writeFile(workbook, `${fileName}${EXCEL_EXTENSION}`);

  }
  
  /* Creates excel from Array.
  * https://dev.to/idrisrampurawala/exporting-data-to-excel-and-csv-in-angular-3643
  * @param element DOM table element reference.
  * @param fileName filename to save as.
  */
 public exportTableArrayToExcel(element: any, fileName: string): void {
   const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(element);
   // generate workbook and add the worksheet
   const workbook: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
   // save to file
   XLSX.writeFile(workbook, `${fileName}${EXCEL_EXTENSION}`);

 }
}
