import { Component, Input, OnInit } from '@angular/core';
import { MapService } from 'src/app/estratos-components/map/map.service';

@Component({
  selector: 'app-average-variation',
  templateUrl: './average-variation.component.html',
  styleUrls: ['./average-variation.component.scss']
})
export class AverageVariationComponent implements OnInit {

  show = true;
  averageOrVariation = "average"
  @Input() view = ''

  constructor(private mapService:MapService) {
    this.mapService.showLandChanged.subscribe(
      b => { 
        this.show = b;        
      }
    )
    this.mapService.landWhatToShowChanged.subscribe(
      b => { 
        if(b == 'average' || b == 'variation' || b == 'quality'){
          if(this.averageOrVariation == 'average' || this.averageOrVariation == 'variation'){
            this.averageOrVariation = b;
          }
          this.show = true;
        }else{
          this.show = false;
        }    
      }
    )
  }

  ngOnInit(): void {
  }

  toggleAvgVar(){
    let state = 'average'
    if(this.averageOrVariation =='average'){
      state = 'variation'
    }
    this.mapService.changeLandWhatToShow(state);
  }

}
