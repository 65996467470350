import { ChangeDetectorRef } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { LandModel } from '../../../land.model';
import { LandsService } from '../../../lands.service';

@Component({
  selector: 'app-land-info',
  templateUrl: './land-info.component.html',
  styleUrls: ['./land-info.component.scss']
})
export class LandInfoComponent implements OnInit {

  @Input() land:LandModel;
  totalYears:number = 0;
  daysLeft:number = 0;

  constructor(private landsService: LandsService,private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    if(this.totalYears == 0){
      let subscription = this.landsService.landSelected.subscribe(
        land => {
          if(land){
            if(land.plots !=undefined && this.totalYears == 0){
              if(land.plots[0].files != undefined){
                this.howManyYears();
                subscription.unsubscribe();
              }
            }
          }
        }
      )
    }
  }

  howManyYears(){
    let oldestDate = new Date();
    for(let i=0;i<this.land.plots.length;i++){
      let files = this.land.plots[i].files;
      for(let j=0;j<files.length;j++){
        let dateFromFile = files[j]['Date_timeline']
        if(oldestDate>dateFromFile){
          oldestDate = dateFromFile;
        }
      }
    }
    let today = new Date();
    let dateDif:number = (today.getTime() - oldestDate.getTime())/ (1000 * 60 * 60 * 24);
    this.totalYears = Math.trunc(dateDif / 365);
    this.daysLeft = Math.trunc(365 - (dateDif % 365));
    this.ref.detectChanges();
  }

}
