<p>Select index:</p>
<select class="form-control form-control-solid" name="index" (change)="toggleIndex($event.target.value)">
  <option value="ndvi absolute" [selected]="index == 'ndvi absolute'">NDVI</option>
  <option value="ndvi relative" [selected]="index == 'ndvi relative'">NDVI Relative</option>
  <option value="tcari" [selected]="index == 'tcari'">Transformed TCARI/OSAVI</option>
  <option value="ndwi" [selected]="index == 'ndwi'">Transformed NDWI</option>
  <option value="evi" [selected]="index == 'evi'">EVI</option>
  <option value="pixels" [selected]="index == 'pixels'">Pixels</option>
</select>
<!-- <div class="form-group mb-0 row align-items-center">
    <label class="col-8 col-form-label">NDVI:</label>
    <div class="col-4 d-flex justify-content-end">
      <span class="switch switch-sm switch-primary">
        <label>
          <input type="radio" [checked]="index == 'ndvi absolute'" name="index" (change)="toggleIndex('ndvi absolute')" />
          <span></span>
        </label>
      </span>
    </div>
</div>
<div class="form-group mb-0 row align-items-center">
    <label class="col-8 col-form-label">NDVI Relative:</label>
    <div class="col-4 d-flex justify-content-end">
      <span class="switch switch-sm switch-primary">
        <label>
          <input type="radio" [checked]="index == 'ndvi relative'" name="index" (change)="toggleIndex('ndvi relative')" />
          <span></span>
        </label>
      </span>
    </div>
</div>
<div class="form-group mb-0 row align-items-center">
    <label class="col-8 col-form-label">Transformed TCARI/OSAVI:</label>
    <div class="col-4 d-flex justify-content-end">
      <span class="switch switch-sm switch-primary">
        <label>
          <input type="radio" [checked]="index == 'tcari'" [disabled]="haveIndex('tcari')" name="index" (change)="toggleIndex('tcari')" />
          <span></span>
        </label>
      </span>
    </div>
</div>
<div class="form-group mb-0 row align-items-center">
    <label class="col-8 col-form-label">Transformed NDWI:</label>
    <div class="col-4 d-flex justify-content-end">
      <span class="switch switch-sm switch-primary">
        <label>
          <input type="radio" [checked]="index == 'ndwi'" [disabled]="haveIndex('ndwi')" name="index" (change)="toggleIndex('ndwi')" />
          <span></span>
        </label>
      </span>
    </div>
</div>
<div class="form-group mb-0 row align-items-center">
    <label class="col-8 col-form-label">EVI:</label>
    <div class="col-4 d-flex justify-content-end">
      <span class="switch switch-sm switch-primary">
        <label>
          <input type="radio" [checked]="index == 'evi'" [disabled]="haveIndex('evi')" name="index" (change)="toggleIndex('evi')" />
          <span></span>
        </label>
      </span>
    </div>
</div> -->
<!-- <horizontal-timeline [showContent]="false" [eventsMinDistance]="40" [relativeDistance]="false"></horizontal-timeline> -->
