import { Component, OnInit } from "@angular/core";
import { Alert } from "./alert.model";
import { AlertsService } from "./alerts.service";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-alerts-maps",
  templateUrl: "./alerts.component.html",
  styleUrls: ["./alerts.component.scss"],
})
export class AlertsMapsComponent implements OnInit {
  alerts: any = {};
  objectKeys = Object.keys;
  keyToShow = "";

  constructor(private alertsService: AlertsService) {}

  ngOnInit(): void {}
  async ngAfterViewInit() {
    // console.log("alerts")
    this.alerts = await this.alertsService.getAlertsFromServer();
    /* let alert = new Alert()
    alert.pk= 'pk';
    alert.name= '1';
    alert.date= new Date().toLocaleDateString();
    alert.url= 'rul';
    alert.field='plot';
    alert.station='land';
    alert.description= 'type';
    alert.value=0
    this.alerts[1]=[alert]
    let alert2 = new Alert()
    alert2.pk= 'pk';
    alert2.name= '2';
    alert2.date= new Date().toLocaleDateString();
    alert2.url= 'rul';
    alert2.field='plot';
    alert2.station='land';
    alert2.description= 'type';
    alert2.value=0
    this.alerts[2]=[alert2]
    let alert3 = new Alert()
    alert3.pk= 'pk';
    alert3.name= '3';
    alert3.date= new Date().toLocaleDateString();
    alert3.url= 'rul';
    alert3.field='plot';
    alert3.station='land';
    alert3.description= 'type';
    alert3.value=0
    this.alerts[3]=[alert3]
    let alert4 = new Alert()
    alert4.pk= 'pk';
    alert4.name= '4';
    alert4.date= new Date().toLocaleDateString();
    alert4.url= 'rul';
    alert4.field='plot';
    alert4.station='land';
    alert4.description= 'type';
    alert4.value=0
    this.alerts['other']=[alert4]
    let alert5 = new Alert()
    alert5.pk= 'pk';
    alert5.name= 'a';
    alert5.date= new Date().toLocaleDateString();
    alert5.url= 'rul';
    alert5.field='plot';
    alert5.station='land';
    alert5.description= 'type';
    alert5.value=0
    this.alerts['other'].push(alert5) */
    // console.log(this.alerts)
  }

  changeAlert(n) {
    this.keyToShow = n;
  }
  markOneViewed(id) {
    // delete notification from collection
    dance: for (let k in this.alerts) {
      for (let i = 0; i < this.alerts[k].length; i++) {
        if (this.alerts[k][i].pk == id) {
          delete this.alerts[k][i];
          // removes empty entries
          this.alerts[k] = this.alerts[k].filter(function (el: any) {
            return el;
          });
          if (this.alerts[k].length == 0) {
            delete this.alerts[k];
          }
          // break both iterations
          break dance;
        }
      }
    }
    this.alertsService.markOneViewed(id);
  }
  markAllAsViewed() {
    // console.log("mark all")
    this.alerts = {};
    this.alertsService.markAllAsViewed();
  }
}
