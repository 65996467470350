import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map/map/map.component';
import { MapWpComponent } from './map/map-wp/map-wp.component';
import { PlotsComponent } from './lands/lands/plots/plots.component';
import { RecomendationsComponent } from './lands/lands/recomendations/recomendations.component';
import { FilesComponent } from './lands/lands/plots/files/files.component';
import { SensorsComponent } from './lands/lands/plots/sensors/sensors.component';
import { IssuesComponent } from './lands/lands/plots/issues/issues.component';
import { ProductionsComponent } from './lands/lands/productions/productions.component';
import { AlertsMapsComponent } from './lands/lands/alerts/alerts.component';
import { EnterprisesComponent } from './companies/enterprises/enterprises.component';
import { LandsComponent } from './lands/lands/lands.component';
import { LandsOverviewComponent } from './map/lands-overview/lands-overview.component';
import { MapFilter } from './map/filter/filter.service';
import { LandDetailService } from './lands/lands/land-detail/land-detail.service';
import { LandListService } from './lands/lands/land-list/land-list.service';
import { MapCenterView } from './map/centerView/center-view.service';
import { PrintPolygonsService } from './map/print-polygons/print-polygons.service';
import { LandInfoComponent } from './lands/lands/land-detail/land-info/land-info.component';
import { LandActionsComponent } from './lands/lands/land-detail/land-actions/land-actions.component';
import { TogglePlotsComponent } from './lands/lands/land-detail/map-interaction/toggle-plots/toggle-plots.component';
import { ConditionalStyleService } from './map/styles/conditional-style.service';

import { GraphsComponent } from './lands/lands/graphs/graphs.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { AverageVariationStyleService } from './map/styles/land/average-variation.service';
import { QualityStyleService } from './map/styles/land/quality.service';
import { MapFilesService } from './map/map-files/map-files.service';
import { QualityComponent } from './lands/lands/land-detail/map-interaction/quality/quality.component';
import { AverageVariationComponent } from './lands/lands/land-detail/map-interaction/average-variation/average-variation.component';
import { MapFilesComponent } from './lands/lands/land-detail/map-interaction/showmaps/map-files.component';
import { AverageMapService } from './map/average-variation/average-map.service';
import { StreetMapComponent } from './lands/lands/land-detail/map-interaction/street-map/street-map.component';
import { NdviStyleService } from './map/styles/indexes/ndvi.service';
import { SelectIndexComponent } from './lands/lands/land-detail/map-interaction/select-index/select-index.component';
import { EviStyleService } from './map/styles/indexes/evi.service';
import { NdwiStyleService } from './map/styles/indexes/ndwi.service';
import { TcariStyleService } from './map/styles/indexes/tcari.service';
import { PixelsStyleService } from './map/styles/indexes/pixels.service';
import { MapLegendComponent } from './lands/lands/land-detail/map-interaction/map-legend/map-legend.component';
/* import { HorizontalTimelineComponent } from './lands/lands/land-detail/map-interaction/horizontal-timeline/horizontal-timeline.component'; */
import { ClientComponent } from './lands/opn/client/client.component';
import { StationComponent } from './lands/opn/station/station.component';
import { ClientsListComponent } from './lands/opn/clients-list/clients-list.component';
import { HeatmapComponent } from './lands/opn/heatmap/heatmap.component';
import { AnalyticsComponent } from './lands/opn/analytics/analytics.component';
import { GraphToolsComponent } from './lands/lands/graphs/graph-tools/graph-tools.component';
import { WeatherComponent } from './lands/lands/land-detail/weather/weather.component';
import { WeatherService } from './lands/lands/land-detail/weather/weather.service';


@NgModule({
  declarations: [
    MapComponent,
    MapWpComponent,
    LandsComponent,
    PlotsComponent,
    RecomendationsComponent,
    FilesComponent,
    SensorsComponent,
    IssuesComponent,
    ProductionsComponent,
    AlertsMapsComponent,
    EnterprisesComponent,
    LandsOverviewComponent,
    LandInfoComponent,
    LandActionsComponent,
    StreetMapComponent,
    TogglePlotsComponent,
    QualityComponent,
    AverageVariationComponent,
    MapFilesComponent,
    GraphsComponent,
    SelectIndexComponent,
    MapLegendComponent,
    /* HorizontalTimelineComponent, */
    ClientComponent,
    StationComponent,
    ClientsListComponent,
    HeatmapComponent,
    AnalyticsComponent,
    GraphToolsComponent,
    WeatherComponent
  ],
  imports: [
    CommonModule,
    HighchartsChartModule
  ],
  exports: [
    MapComponent,
    MapWpComponent,
    LandsOverviewComponent,
    LandsComponent,
    LandInfoComponent,
    LandActionsComponent,
    StreetMapComponent,
    TogglePlotsComponent,
    QualityComponent,
    AverageVariationComponent,
    MapFilesComponent,
    GraphsComponent,
    MapLegendComponent,
    WeatherComponent,
    AlertsMapsComponent
  ],
  providers: [
    MapFilter,
    LandDetailService,
    LandListService,
    PrintPolygonsService,
    MapCenterView,
    ConditionalStyleService,
    AverageVariationStyleService,
    AverageMapService,
    QualityStyleService,
    MapFilesService,
    NdviStyleService,
    EviStyleService,
    NdwiStyleService,
    TcariStyleService,
    PixelsStyleService,
    WeatherService
  ]
})
export class EstratosModule { }
