
<div class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5" *ngIf="(alerts | json) != '{}'">
  <ul class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1" role="tablist">
    <li class="nav-item" *ngFor="let key of objectKeys(alerts)">
      <a class="nav-link cursor-pointer" data-toggle="tab" *ngIf="key==1" [ngClass]="{'active': keyToShow==key, 'show': keyToShow==key}" (click)="changeAlert(key)">
        Variation ({{alerts[key].length}})
      </a>
      <a class="nav-link cursor-pointer" data-toggle="tab" *ngIf="key==2" [ngClass]="{'active': keyToShow==key, 'show': keyToShow==key}" (click)="changeAlert(key)">
        Traps ({{alerts[key].length}})
      </a>
      <a class="nav-link cursor-pointer" data-toggle="tab" *ngIf="key==3" [ngClass]="{'active': keyToShow==key, 'show': keyToShow==key}" (click)="changeAlert(key)">
        Water ({{alerts[key].length}})
      </a>
      <a class="nav-link cursor-pointer" data-toggle="tab" *ngIf="key=='other'" [ngClass]="{'active': keyToShow==key, 'show': keyToShow==key}" (click)="changeAlert(key)">
        Other ({{alerts[key].length}})
      </a>
    </li>
  </ul>
</div>
<ng-container *ngIf="(alerts | json) != '{}'">
  <div class="navi navi-icon-circle navi-spacer-x-0">
    <div class="navi-item borrar" (click)="markAllAsViewed()">
      <div class="navi-link rounded">
        <div class="navi-text">
          <div class="font-weight-bold font-size-lg">
            Mark all as viewed
          </div>
        </div>
        <div class="symbol symbol-25 ml-3">
          <div class="symbol-label borrar">
            <i class="flaticon-delete text-success icon-md"></i>
          </div>
        </div>
      </div>
    </div>
     <!--begin::Item-->
     <ng-container  [ngSwitch]="key" *ngFor="let key of objectKeys(alerts)">
      <ng-container *ngIf="keyToShow==key">
        <ng-container *ngFor="let alert of alerts[key]">
          <div class="navi-item">
            <div class="navi-link rounded">
              <div class="symbol symbol-60 mr-3">
                <div class="symbol-label text-danger">
                  <ng-container>
                    {{alert.value}}
                  </ng-container>
                </div>
              </div>
              
              <a href="{{alert.url}}" class="navi-text">
                <div class="font-weight-bold font-size-lg">
                  {{alert.name}} on {{alert.station}}
                </div>
                <div class="text-muted">
                  ({{alert.date}}) {{alert.field}} 
                </div>
              </a>
              <div class="symbol symbol-25 ml-3">
                <div class="symbol-label borrar" (click)="markOneViewed(alert.pk)">
                  <i class="flaticon-delete text-success icon-md"></i>
                </div>
              </div>
            </div>
          </div>
        </ng-container>   
      </ng-container>        
     </ng-container>     
    <!--end::Item-->
  </div>
</ng-container>
<div *ngIf="(alerts | json) == '{}'">
  <p>No notifications.</p>
</div>


