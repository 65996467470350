import { Injectable } from '@angular/core';
import Map from 'ol/Map';
import Layer from 'ol/layer/Layer';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import { Style, Fill, Stroke, Circle, Icon } from 'ol/style';
import { Plot } from '../../lands/lands/plots/plot.model';
import {getCenter} from 'ol/extent';



@Injectable()
export class MapCenterView {
    /*
    grabs all features from land
    adds them to "centers" vectorsource
    fits the map to "centers"
     */
    toPlots(map: Map, plots: Plot[]) {
        let centers = new VectorSource();
        map.getLayers().forEach(function (layer) {
            if (layer instanceof Layer) {
                // searches for layers named as "what"
                // where what == "land" or "plot"
                if (layer.get('name') == 'land') {
                    let source = new VectorSource();
                    source = layer.getSource();
                    if (source.getFeatures().length > 0) {
                        // iterates each feature
                        source.getFeatures().forEach(function (feature) {
                            for(let i=0;i<plots.length;i++){
                                let plot = plots[i];
                                if(plot.pk == feature.get("pk_plot")){
                                    centers.addFeature(feature);
                                    break;
                                }
                            }
                        });
                    }
                    return;
                }
            }
        });
        if(centers.getFeatures().length>0){
            map.getView().fit(centers.getExtent(), { constrainResolution: false,padding: [20, 20, 20, 20]});
        }
    }
}