import { UserModel } from '../../modules/auth';
import { Company } from '../companies/company.model';
import { Plot } from './lands/plots/plot.model';
import { Recomendation } from './lands/recomendations/recomendation.model';
import { WPart } from './wpart/wpart.model';

export class LandModel {
    pk:string;
    name:string;
    description:string;
    variation:number;
    planet:string;
    planetName:string;
    area:number;
    ortoFile:string;
    planetValues:string;
    idExternal:string;
    processingStatus:string;
    firstImage:Date;
    invoice:Date;
    payment:string;
    variationNotification:boolean;
    reviewed:boolean;
    packStress:boolean;
    midSeason:boolean;
    weatherOn:boolean;
    experiment:boolean;
    summer:boolean;
    winter:boolean;
    satellite:boolean;
    plots:Plot[];
    recomendations:Recomendation[];
    averages:object[];
    owner:UserModel;
    company:Company;
    weather_now:{};
    weather_prev:{};
    weather_hist:{};
    active:boolean;
    indexesAndDates:{};
    workparts_val:WPart[];
    workparts_pen:WPart[];
}
