export class LandFile {
    pk:string;
    type:string;
    name:string;
    source:string;
    extra_info:string;
    report:string;
    extra_average:string;
    extra_std:string;
    extra_total_area:string;
    extra_max_value:number;
    extra_min_value:number;
    classes:string;
    location:string;
    active:boolean;
    Date_timeline:Date;
    colors:any[];
}
