import { Component, OnInit } from '@angular/core';
import { MapService } from 'src/app/estratos-components/map/map.service';

@Component({
  selector: 'app-quality',
  templateUrl: './quality.component.html',
  styleUrls: ['./quality.component.scss']
})
export class QualityComponent implements OnInit {

  /* show = true; */
  quality = false;

  constructor(private mapService:MapService) {
    /* this.mapService.showLandChanged.subscribe(
      b => { 
        this.show = b;        
      }
    ) */
    this.mapService.landWhatToShowChanged.subscribe(
      b => { 
        if(b == 'quality'){
          this.quality = true;
        }else{
          this.quality = false;
        }    
      }
    )
  }

  ngOnInit(): void {
  }

  toggleQuality(){
    console.log("quality")
    let state = 'quality';
    if(this.quality){
      state = 'quality off'
    }
    this.mapService.changeLandWhatToShow(state);
  }

}
