<!-- <p>graphs works!</p> -->
<!-- <img width='12' src='./assets/media/estratos/arrow-pointing-to-right.svg'> -->
<!-- <app-graph-tools></app-graph-tools> -->
<div style="justify-content: center; align-items: center; flex-direction: column;">
  <highcharts-chart
    [Highcharts]="Highcharts"
    [options]="chartOptions"
    [(update)]="updateFlag"
    style="min-width: 900px; height: 250px; display: block;position:relative;"
  ></highcharts-chart>
</div>
<!-- <div id="chartOne" style="min-width: 310px; max-width: 800px; height: 250; margin: 0 auto">
</div> -->
