import { EventEmitter, Injectable, Output } from '@angular/core';
import * as Highcharts from 'highcharts';
import { GraphAnalyticsService } from './blocks/graph-analytics.service';
import { GraphOpnService } from './blocks/graph-opn.service';
import { GraphPlotsService } from './blocks/graph-plots.service';
import { MapService } from 'src/app/estratos-components/map/map.service';


@Injectable({
  providedIn: 'root'
})
export class GraphsService {

  constructor(private mapService: MapService, private graphAnalyticsService: GraphAnalyticsService, private graphOpnService: GraphOpnService, private graphPlotsService: GraphPlotsService) { }

  getIndexVal(){
    let index = this.mapService.getIndex();
    let fin_index = 'NDVI';
    if(index == 'ndwi'){
      fin_index = 'Absolute tNDWI';
    }
    if(index == 'tcari'){
      fin_index = 'tTCARI/OSAVI';
    }
    return fin_index
  }
  getRealName(ind){
    let fin_index = '';
    if(ind == 'ndwi'){
      fin_index = 'Absolute tNDWI';
    }
    if(ind == 'tcari'){
      fin_index = 'tTCARI/OSAVI';
    }
    return fin_index
  }
  getAxisYPlotFiles(where){
    let fin_index = this.getIndexVal();
    let axisY = [];
    axisY.push({ // Average yAxis
          gridLineWidth: 0,
          title: {
              text: 'Average '+fin_index,
              style: {
                  color: Highcharts.getOptions().colors[1]
              }
          },
          labels: {
              format: '{value}',
              style: {
                  color: Highcharts.getOptions().colors[1]
              }
          },
          opposite: true,
          startOnTick: false,
          endOnTick: false
      })
    if(where == 'PlotFiles'){
      axisY.push({ // Temperature yAxis
            labels: {
                format: '{value}°C',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            title: {
                text: 'Temperature',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            opposite: true
        })
      axisY.push({ // ETO yAxis
            labels: {
                format: '{value} mm/day',
                style: {
                    color: Highcharts.getOptions().colors[2]
                }
            },
            title: {
                text: 'ETo',
                style: {
                    color: Highcharts.getOptions().colors[2]
                }
            }
        })
      axisY.push({ // Humidity yAxis
            labels: {
                format: '{value} %',
                style: {
                    color: Highcharts.getOptions().colors[4]
                }
            },
            title: {
                text: 'Humidity',
                style: {
                    color: Highcharts.getOptions().colors[4]
                }
            },
            max:100,
            min:0,
        })
    }



    return axisY;
  }
  cleanValuesPlot(values){
    return this.graphPlotsService.cleanValuesPlotFiles(values)
  }
  cleanValuesPlotYear(values){
    return this.graphPlotsService.cleanValuesPlotFilesYear(values)
  }
  cleanValuesWeather(values){
    return this.graphPlotsService.cleanValuesPlotWeather(values)
  }
  cleanValuesAnalytics(values){
    return this.graphAnalyticsService.cleanValues(values)
  }
  cleanValuesOPNAnalytics(values,where,filters){
    return this.graphOpnService.cleanValues(values,where,filters)
  }
  getSeriesPlot(plot_values,plot_values_extra,where){
    let axisSeries = [];
    console.log(where)
    console.log(plot_values)
    console.log(plot_values_extra)
    let fin_index = this.getIndexVal();
    if(where == 'PlotFiles'){
      axisSeries.push({
          name: `Average ${fin_index}`,
          type: 'line',
          yAxis: 0,
          data: JSON.parse(JSON.stringify(plot_values[0])),
          label:{
            enabled:false
          },
          color: Highcharts.getOptions().colors[1],
          dashStyle: 'ShortDot',
          point:{
            events:{
              click:function(){
                console.log('Clickin')
                console.log(this)
                // for(var t=0;t<this.plot_values_1.length;t++){
                //   if(this.plot_values_1[t][0]==this.x){
                //     $("#"+this.plot_values_1[t][3]).trigger("click");
                //     $("#"+this.plot_values_1[t][3]).focus();
                //   }
                // }

              }
            }
          },
          marker: {
              enabled: true,
              symbol: 'diamond'
          },
          tooltip: {
            pointFormatter: function(){
              var percentageSeries = 0;
              var iconPercen = 'arrow-pointing-to-right.svg';
              //console.log('Hellos');
              for(var i=0;i<plot_values[0].length;i++){
                if(plot_values[0][i][0]==this.x){
                  percentageSeries = plot_values[0][i][2];
                  iconPercen = 'arrow-up.svg';
                  if(plot_values[0][i][2] < 10){
                    iconPercen = 'arrow-pointing-to-right.svg';
                  }
                  if(plot_values[0][i][2] < -10){
                    iconPercen = 'arrow-pointing-down.svg';
                  }
                  break;
                }
                else{
                  //console.log('Percen 0');
                  percentageSeries = 0;
                  iconPercen = 'arrow-pointing-to-right.svg';
                }
              }
              console.log(iconPercen)
              // return `Average ${indexType.toUpperCase()}: <span style='font-weight:800;'>${this.y}</span><br />Variation: <span style='font-weight:800;'>${percentageSeries}% <img width='12' src='{% static 'images/' %}${iconPercen}'></span><br /><a href='{% url 'lands:analytics_lands' id_land_flag.pk %}' target='_blank' style='text-align:center;margin-left:20%;'> Analytics</a><br />`;
              return `Average NDVI: <span style='font-weight:800;'>${this.y}</span><br />Variation: <span style='font-weight:800;'>${percentageSeries}% <img width='12' src='./assets/media/estratos/${iconPercen}'></span><br />`;
            },
            xDateFormat: '%Y, %b %e'
          },
          zIndex: 3
      })
      axisSeries.push({
          name: `C.Variation(CV)`,
          type: 'line',
          yAxis: 0,
          marker: {
            enabled: true,
            symbol: 'triangle'
          },
          color: '#FFA500',
          dashStyle: 'DashDot',
          data: JSON.parse(JSON.stringify(plot_values[1])),
          label:{
            enabled:false
          },
          tooltip: {
            xDateFormat: '%Y, %b %e'
          },
          zIndex: 2
      })
    }
    if(where == 'PlotFilesYear'){
      let ndvi_v = []
      let cv_v = []
      let extr_v = []
      let yearslist = []
      let ind_extr = '';
      let vhh = plot_values[0];
      for(let i=0;i<vhh.length;i++){
        // console.log(vhh[i])
        let datiis = new Date(vhh[i][0])
        let year = datiis.getFullYear();
        vhh[i].push(year)
        if(!yearslist.includes(year)){
          yearslist.push(year)
          ndvi_v[year]=[]
          cv_v[year]=[]
          extr_v[year]=[]
        }
      }
      for(let i=0;i<vhh.length;i++){
        let uye = vhh[i][5]
        console.log()
        if(vhh[i][4]=='3'){
          ndvi_v[uye].push(vhh[i])
        }
        else{
          ind_extr = vhh[i][4];
          extr_v[uye].push(vhh[i])
        }
      }
      let vhh2 = plot_values[1];
      for(let i=0;i<vhh2.length;i++){
        let datiis = new Date(vhh2[i][0])
        let uye = datiis.getFullYear();
        cv_v[uye].push(vhh2[i])
      }
      console.log(ndvi_v)
      console.log(extr_v)
      console.log(cv_v)
      for(let key in ndvi_v){
        axisSeries.push({
            name: `Average NDVI (${key})`,
            type: 'line',
            yAxis: 0,
            data: JSON.parse(JSON.stringify(ndvi_v[key])),
            label:{
              enabled:false
            },
            // color: Highcharts.getOptions().colors[1],
            dashStyle: 'ShortDot',
            point:{
              events:{
                click:function(){
                  console.log('Clickin')
                  console.log(this)
                  // for(let key in ndvi_v){
                  //   for(var t=0;t<ndvi_v[key].length;t++){
                  //     if(ndvi_v[key][t][0]==this.x){
                  //       //modifyNDVI(allYearsNDVI[t][3])
                  //       //console.log(allYearsNDVI[t][3])
                  //       $("#"+ndvi_v[key][t][3]).trigger("click");
                  //       $("#"+ndvi_v[key][t][3]).focus();
                  //     }
                  //   }
                  // }

                }
              }
            },
            marker: {
                enabled: true,
                symbol: 'diamond'
            },
            tooltip: {
              pointFormatter: function(){
                var percentageSeries = 0;
                var iconPercen = 'arrow-pointing-to-right.svg';
                //console.log('Hellos');
                // for(var i=0;i<plot_values[0].length;i++){
                //   if(plot_values[0][i][0]==this.x){
                //     percentageSeries = plot_values[0][i][2];
                //     iconPercen = 'arrow-up.svg';
                //     if(plot_values[0][i][2] < 10){
                //       iconPercen = 'arrow-pointing-to-right.svg';
                //     }
                //     if(plot_values[0][i][2] < -10){
                //       iconPercen = 'arrow-pointing-down.svg';
                //     }
                //     break;
                //   }
                //   else{
                //     //console.log('Percen 0');
                //     percentageSeries = 0;
                //     iconPercen = 'arrow-pointing-to-right.svg';
                //   }
                // }
                console.log(iconPercen)
                // return `Average ${indexType.toUpperCase()}: <span style='font-weight:800;'>${this.y}</span><br />Variation: <span style='font-weight:800;'>${percentageSeries}% <img width='12' src='{% static 'images/' %}${iconPercen}'></span><br /><a href='{% url 'lands:analytics_lands' id_land_flag.pk %}' target='_blank' style='text-align:center;margin-left:20%;'> Analytics</a><br />`;
                // return `Average NDVI: <span style='font-weight:800;'>${this.y}</span><br />Variation: <span style='font-weight:800;'>${percentageSeries}% <img width='12' src='./assets/media/estratos/${iconPercen}'></span><br />`;
                return `Average NDVI: <span style='font-weight:800;'>${this.y}</span><br />`;
              },
              xDateFormat: '%Y, %b %e'
            },
            zIndex: 3
        })
      }
      for(let key in cv_v){
        axisSeries.push({
            name: `C.Variation(CV) (${key})`,
            type: 'line',
            yAxis: 0,
            marker: {
              enabled: true,
              symbol: 'triangle'
            },
            // color: '#FFA500',
            dashStyle: 'DashDot',
            data: JSON.parse(JSON.stringify(cv_v[key])),
            label:{
              enabled:false
            },
            tooltip: {
              xDateFormat: '%Y, %b %e'
            },
            zIndex: 2
        })
      }
      if(ind_extr != ''){
        console.log('EXTRAS')
        let fiisn = this.getRealName(ind_extr)
        for(let key in extr_v){
          axisSeries.push({
              name: `Average ${fiisn} (${key})`,
              type: 'line',
              yAxis: 0,
              data: JSON.parse(JSON.stringify(extr_v[key])),
              label:{
                enabled:false
              },
              // color: Highcharts.getOptions().colors[1],
              dashStyle: 'ShortDot',
              point:{
                events:{
                  click:function(){
                    console.log('Clickin')
                    console.log(this)
                  }
                }
              },
              marker: {
                  enabled: true,
                  symbol: 'diamond'
              },
              tooltip: {
                pointFormatter: function(){
                  var percentageSeries = 0;
                  var iconPercen = 'arrow-pointing-to-right.svg';
                  console.log(iconPercen)
                  // return `Average ${indexType.toUpperCase()}: <span style='font-weight:800;'>${this.y}</span><br />Variation: <span style='font-weight:800;'>${percentageSeries}% <img width='12' src='{% static 'images/' %}${iconPercen}'></span><br /><a href='{% url 'lands:analytics_lands' id_land_flag.pk %}' target='_blank' style='text-align:center;margin-left:20%;'> Analytics</a><br />`;
                  // return `Average NDVI: <span style='font-weight:800;'>${this.y}</span><br />Variation: <span style='font-weight:800;'>${percentageSeries}% <img width='12' src='./assets/media/estratos/${iconPercen}'></span><br />`;
                  return `Average ${fiisn}: <span style='font-weight:800;'>${this.y}</span><br />`;
                },
                xDateFormat: '%Y, %b %e'
              },
              zIndex: 3
          })
        }
      }
    }

    if(plot_values_extra.length>0){
        axisSeries.push({
           name: 'Temperature',
           type: 'spline',
           marker: {
                enabled: true,
                symbol: 'circle'
            },
            color: '#7CB5EC',
           data: JSON.parse(JSON.stringify(plot_values_extra[0])),
           yAxis: 1,
           label:{
             enabled:false
           },
           tooltip: {
               valueSuffix: ' °C',
               xDateFormat: '%Y, %b %e'
           }
       })
      //  axisSeries.push({
      //     name: 'Rain',
      //     type: 'spline',
      //     marker: {
      //          enabled: true,
      //          symbol: 'circle'
      //      },
      //      color: '#7CB5EC',
      //     data: JSON.parse(JSON.stringify(this.plot_values_extra[0])),
      //     yAxis: 0,
      //     label:{
      //       enabled:false
      //     },
      //     tooltip: {
      //         valueSuffix: ' °C',
      //         xDateFormat: '%Y, %b %e'
      //     }
      // })
      axisSeries.push({
         name: 'Humidity',
         type: 'spline',
         marker: {
              enabled: true,
              symbol: 'circle'
          },
          color: Highcharts.getOptions().colors[4],
         data: JSON.parse(JSON.stringify(plot_values_extra[2])),
         yAxis: 3,
         label:{
           enabled:false
         },
         tooltip: {
            valueDecimals: 2,
            valueSuffix: '%',
            xDateFormat: '%Y, %b %e'
         }
       })
     axisSeries.push({
        name: 'ETo',
        type: 'spline',
        marker: {
             enabled: true,
             symbol: 'square'
         },
         color: '#90ed7d',
        data: JSON.parse(JSON.stringify(plot_values_extra[3])),
        yAxis: 2,
        label:{
          enabled:false
        },
        tooltip: {
            valueSuffix: ' mm/day',
            xDateFormat: '%Y, %b %e'
        }
      })
    }
    return axisSeries
  }

  getAxisYAnalyticsLand(){
    let axisY = [];
    axisY.push({ // Average yAxis
          gridLineWidth: 0,
          title: {
              text: 'Average NDVI',
              style: {
                  color: Highcharts.getOptions().colors[1]
              }
          },
          labels: {
              format: '{value}',
              style: {
                  color: Highcharts.getOptions().colors[1]
              }
          },
          opposite: true,
          startOnTick: false,
          endOnTick: false
      })
    return axisY;
  }
  getAxisYOPNAnalytics(){
    // console.log(traps)
    let axisY = [];
    axisY.push({ // Average yAxis
          gridLineWidth: 0,
          title: {
              text: 'Valor/Cantidad de lecturas',
              style: {
                  color: Highcharts.getOptions().colors[0]
              }
          },
          labels: {
              format: '{value}',
              style: {
                  color: Highcharts.getOptions().colors[0]
              }
          },
          opposite: true,
          visible: true
      })
    return axisY;
  }
  // getAxisYOPNStation(){
  //   let axisY = [];
  //   axisY.push({ // Average yAxis
  //         gridLineWidth: 0,
  //         title: {
  //             text: 'Average NDVI Plot',
  //             style: {
  //                 color: Highcharts.getOptions().colors[1]
  //             }
  //         },
  //         labels: {
  //             format: '{value}',
  //             style: {
  //                 color: Highcharts.getOptions().colors[1]
  //             }
  //         },
  //         opposite: true,
  //         startOnTick: false,
  //         endOnTick: false
  //     })
  //   return axisY;
  // }
  getAxisXPlotFiles(where){
    let axisX = [];
    axisX.push({type: "datetime"})
    return axisX;
  }
  getAxisXAnalyticsLand(){
    let axisX = [];
    axisX.push({type: "datetime"})
    return axisX;
  }
  getAxisXOPNAnalytics(){
    let axisX = [];
    axisX.push({type: "linear"},{title:{text: "Semana"}})
    return axisX;
  }
  // getAxisXOPNStation(){
  //   let axisX = [];
  //   axisX.push({type: "datetime"})
  //   return axisX;
  // }
  // getSeriesPlot(){
  //
  // }
  getSeriesAnalyticsLand(){

  }
  getSeriesOPNAnalytics(plot_values,where,traps){
    console.log(plot_values)
    let axisSeries = [];
    for(let i=0;i<traps['feromona'].length;i++){
      let textX = 'Average '+traps['feromona'][i]+' - '+traps['year'];
      let series = plot_values[0];
      if(where == 'OPNClient2' || where == 'OPNStation2'){
        textX = 'Average accumulated '+traps['feromona'][i]+' - '+traps['year'];
        series = plot_values[1];
      }
      axisSeries.push({
          name: textX,
          type: 'spline',
          yAxis: 0,
          marker: {
            enabled: true,
            symbol: 'cicle'
          },
          color: '#7CB5EC',
          // dashStyle: 'DashDot',
          data: JSON.parse(JSON.stringify(series)),
          label:{
            enabled:false
          },
          tooltip: {
            pointFormatter: function(){
              var point = this;
            var value = point.series.options.data[point.index][2];
            let listResult = ""
            if(value){
              listResult = value;
            }
              // return `Average ${indexType.toUpperCase()}: <span style='font-weight:800;'>${this.y}</span><br />Variation: <span style='font-weight:800;'>${percentageSeries}% <img width='12' src='{% static 'images/' %}${iconPercen}'></span><br /><a href='{% url 'lands:analytics_lands' id_land_flag.pk %}' target='_blank' style='text-align:center;margin-left:20%;'> Analytics</a><br />`;
              // return `Average NDVI: <span style='font-weight:800;'>${this.y}</span><br />Variation: <span style='font-weight:800;'>${percentageSeries}% <img width='12' src='./assets/media/estratos/${iconPercen}'></span><br />`;
              return `Average: <span style='font-weight:800;'>${this.y}</span><br /><p>${listResult}</p>`;
            },
          },
          zIndex: 2
      })
    }
    console.log(axisSeries)
    // axisSeries.push({
    //     name: `C.Variation(CV)`,
    //     type: 'line',
    //     yAxis: 1,
    //     marker: {
    //       enabled: true,
    //       symbol: 'triangle'
    //     },
    //     color: '#FFA500',
    //     dashStyle: 'DashDot',
    //     data: JSON.parse(JSON.stringify(plot_values[1])),
    //     label:{
    //       enabled:false
    //     },
    //     tooltip: {
    //       xDateFormat: '%Y, %b %e'
    //     },
    //     zIndex: 2
    // })
    return axisSeries;
  }
  getSeriesOPNStation(){

  }
}
