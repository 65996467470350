import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/modules/auth";
import { environment } from "src/environments/environment";
import { LandModel } from "../../land.model";

@Injectable()
export class LandDetailService {

    constructor(private authService: AuthService, private http: HttpClient){}

    getLandInfo(pk: string, lands:LandModel[]) {
      // console.log('getLandInfo')
        for(let i=0;i<lands.length;i++){
            let land = lands[i];
            if(land.pk == pk){
                return land;
            }
        }
    }

    getLandInfoBd(pk: string){
        // console.log("getLandInfoBd")
        let token = this.authService.getAuthFromLocalStorage().accessToken;
        let data = {
            token: token,
            pk: pk
        };
        // console.log(data)
        let httpOptions2 = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':`Bearer ${token}`})
        };
        return this.http.get(`${environment.apiUrl}/api/v1/lands/land-plots/${pk}`, httpOptions2).pipe(map(result => {
          // console.log(result)
            if(!result['error']){
                return result;
            }
        }))
    }
}
