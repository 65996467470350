import { Injectable } from "@angular/core";
import { Style, Fill, Stroke, Circle, Icon, Text } from 'ol/style';
import Map from 'ol/Map';
import Feature from 'ol/Feature';


@Injectable()
export class NdwiStyleService{
    constructor(){}

    colors(value){
        let color;
        switch (value) {
            case 1:
                color = 'rgba(8,48,106,255)';
                break;
            case 2:
                color = 'rgba(40,120,185,255)';
                break;
            case 3:
                color = 'rgba(115,179,216,255)'
                break;
            case 4:
                color = 'rgba(200,221,240,255)'
                break;
            case 5:
                color = 'rgba(247,251,255,255)'
                break;
            default:
                color = 'rgba(255,255,255,0)';
                break;
        }
        return color;
    }

   getStyle(value){
        let color = this.colors(value);
        return new Style({
            fill: new Fill({
                color: color
            })
        });
    }

    whichValue(f:Feature){
        let value = 0;
        if (f.get('a_cat')) {
            value = f.get('a_cat');
        }else if(f.get('cat')){
            value = f.get('cat');
        }else if(f.get('class')){
            value = f.get('class');
        }else if(f.get('value')){
            value = f.get('value');
        }else if(f.get('ID')){
            value = f.get('ID');
        }
        return value;
    }

    getNdwiStyle(f: Feature) {
        let value = this.whichValue(f);
        return this.getStyle(value);
    }
}
