import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';
import { map } from 'rxjs/operators';

const API_USERS_URL = `${environment.apiUrl}/users`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  // public methods
  login(email: string, password: string): Observable<any> {
    //return this.http.post<AuthModel>(API_USERS_URL,   { email, password });
    // let user = {
    //   username: email,
    //   password: password,
    //   version: '10002'
    // };
    let user = new FormData();
    user.append("username", email);
    user.append("password", password);
    user.append("version", '10002');
    let httpOptions2 = {
      headers: new HttpHeaders()
    };
    return this.http.post(`${environment.apiUrl}/api/v1/account/login`, user, httpOptions2).pipe(map(result => {
      console.log(result);
      /* let resnow = result;
      //console.log(this.resnow);
      if (this.resnow.status == 'error'){
          console.log('Error');
      }
      else{
        this.AuthenticateService.setToken({
            token: this.resnow.token,
            expires: 'no'
        });
        this.httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Token ' + this.AuthenticateService.getToken() })
        };
      } */
      return result;
    }));

  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<any> {
    // JOSEP: PENDENT DE MODIFICAR PER CREAR USUARIS
    let user2 = {
      username: user.email,
      fullname: user.fullname,
      email: user.email,
      password: user.password,
      company: user.companyName,
      version: '10002'
    };
    let httpOptions2 = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };
    return this.http.post(`${environment.apiUrl}/api/v1/account/signup`, user2, httpOptions2).pipe(map(result => {
      console.log(result);
      return result;
    }));
    // return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  getUserByToken(token): Observable<any> {
    /* const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}`, {
      headers: httpHeaders,
    }); */
    console.log(token)
    let data = {
      token: token
    };
    let httpOptions2 = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':`Bearer ${token}`})
    };
    return this.http.get(`${environment.apiUrl}/api/v1/users/user-info`, httpOptions2).pipe(map(result => {
      console.log(result)
      return result
    }));
  }
}
