<div class="form-group mb-0 row align-items-center">
    <label class="col-8 col-form-label">Quality:</label>
    <div class="col-4 d-flex justify-content-end">
      <span class="switch switch-sm switch-primary">
        <label>
          <input type="checkbox" [checked]="quality" name="mapQuality" (change)="toggleQuality()" />
          <span></span>
        </label>
      </span>
    </div>
</div>
