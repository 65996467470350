import { Company } from '../../../../estratos-components/companies/company.model';

export class Alert {
    pk:string;
    name:string;
    description:string;
    field:string;
    value:any;
    station:string;
    multiple:string;
    on:boolean;
    date:string;
    company:Company;
    active:boolean;
    url:string
}