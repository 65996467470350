import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { LandModel } from './land.model';
import { map, catchError, switchMap, finalize, first } from 'rxjs/operators';
import { AuthService } from '../../modules/auth/_services/auth.service';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserModel } from 'src/app/modules/auth';
import { Company } from '../companies/company.model';
import { Plot } from './lands/plots/plot.model';
import { LandFile } from './lands/plots/files/file.model';
import { Router } from '@angular/router';
var clone = require('clone');

@Injectable()
export class LandsAdminService {
    private lands:LandModel[] = [];
    private land:LandModel;
    private land_full:LandModel;
    private plot:Plot;
    private n:number=0;
    @Output() planetSelected = new EventEmitter();
    @Output() plotSelected = new EventEmitter();

    constructor(private http: HttpClient,private  authService: AuthService, private router: Router) { }

    // setLands(lands:LandModel[]){
    //     this.lands = lands;
    // }
    // setLand(land:LandModel){
    //     this.land = land;
    //     this.land_full = clone(this.land);
    // }
    // emitFromStation(){
    //   this.landSelected.emit(this.land);
    // }
    // resetLand(){
    //   // console.log('reset landaaa')
    //     this.land = clone(this.land_full);
    //     // console.log(this.land)
    // }
    // getLands(){
    //     return this.lands;
    // }
    // resumeSavedLands(){
    //   this.landsModified.emit(this.lands);
    // }
    requestLandsListAdmin(){
        // console.log("aqui entra? Land Service request lands")
        let token = this.authService.getAuthFromLocalStorage().accessToken;
        let httpOptions2 = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':`Bearer ${token}`})
        };
        return this.http.get(`${environment.apiUrl}/api/v1/lands/list-admin`, httpOptions2).pipe(map(result => {
            if(!result['error']){
                console.log(result);
                this.lands=[];
                for(let i=0;i<result['data'].length;i++){
                    let entry = result['data'][i];
                    //let variation = JSON.parse(entry['ndvi'])

                    let land = new LandModel;
                    land.pk = entry['land_id'];
                    land.name = entry['land_name'];
                    land.description = entry['land_description'];
                    land.idExternal = entry['land_external_id'];
                    land.owner = new UserModel;
                    land.owner.fullname = entry['owner'];
                    land.company = new Company;
                    land.company.name = entry['company'];
                    let area;
                    if(entry['area'] == null || entry['area'] == undefined || entry['area'] == ''){
                        area = 0;
                    }else{
                        area = +parseFloat(entry['area']).toFixed( 2 );
                    }
                    land.area = area;
                    land.active = entry['active'];
                    land.midSeason = entry['midSeason'];
                    land.packStress = entry['packStress'];
                    land.satellite = entry['satellite']
                    land.summer = entry['summer'];
                    land.winter = entry['winter']
                    this.lands.push(land);
                }
                // this.n = this.n + 20
            }
            return this.lands
        }));
    }

    requestPlanetList(){
        // console.log("aqui entra? Land Service request lands")
        let token = this.authService.getAuthFromLocalStorage().accessToken;
        let httpOptions2 = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':`Bearer ${token}`})
        };
        return this.http.get(`${environment.apiUrl}/api/v1/planet/get-all`, httpOptions2).pipe(map(result => {
            if(!result['error']){
                console.log(result);
                this.lands=[];
                // for(let i=0;i<result['data'].length;i++){
                //     let entry = result['data'][i];
                //     //let variation = JSON.parse(entry['ndvi'])

                //     let land = new LandModel;
                //     land.pk = entry['land_id'];
                //     land.name = entry['land_name'];
                //     land.description = entry['land_description'];
                //     land.idExternal = entry['land_external_id'];
                //     land.owner = new UserModel;
                //     land.owner.fullname = entry['owner'];
                //     land.company = new Company;
                //     land.company.name = entry['company'];
                //     let area;
                //     if(entry['area'] == null || entry['area'] == undefined || entry['area'] == ''){
                //         area = 0;
                //     }else{
                //         area = +parseFloat(entry['area']).toFixed( 2 );
                //     }
                //     land.area = area;
                //     land.active = entry['active'];
                //     land.midSeason = entry['midSeason'];
                //     land.packStress = entry['packStress'];
                //     land.satellite = entry['satellite']
                //     land.summer = entry['summer'];
                //     land.winter = entry['winter']
                //     this.lands.push(land);
                // }
            }
            return this.lands
        }));
    }

    requestPlotsOverview(){
        let token = this.authService.getAuthFromLocalStorage().accessToken;
        let data = {
            token: token
        };
        let httpOptions2 = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':`Bearer ${token}`})
        };
        return this.http.get(`${environment.apiUrl}/api/v1/lands/plots-overview`, httpOptions2).pipe(map(result => {
            if(!result['error']){
                // grab new plot data and adds it to lands
                // for(let i=0;i<result['data'].length;i++){
                //     let entry = result['data'][i];
                //     for(let z=0;z<this.lands.length;z++){
                //         if(this.lands[z].pk == entry['id']){
                //             this.lands[z].plots = []
                //             for(let j=0;j<entry['plots'].length;j++){
                //                 let plot = new Plot;
                //                 plot.pk = entry['plots'][j]['pk'];
                //                 plot.name = entry['plots'][j]['name'];
                //                 plot.coordinates = [];
                //                 for(let x=0;x<entry['plots'][j]['coordinates'].length;x++){
                //                     plot.coordinates.push(entry['plots'][j]['coordinates'][x])
                //                 }
                //                 this.lands[z].plots.push(plot);
                //             }
                //             break;
                //         }
                //     }
                // }
                // this.landsModified.emit(this.lands);
            }
            return result
        }));
    }

    
    // returnLandExists(){
    //   this.landSelected.emit(this.land);
    // }
    // getLand(){
    //     return this.land;
    // }
    // getLandInitial(){
    //     return this.land_full;
    // }
    // saveLandWeather(weath,where){
    //   if(where == 'plots'){
    //     this.land.weather_now = weath['weather_now'];
    //     this.land.weather_prev= weath['weather_5days'];
    //     this.land.weather_hist= weath['weather_hist'];
    //   }
    // }
    // setPlot(plot: Plot){
    //     this.plot = plot;
    //     this.plotSelected.emit(this.plot);
    // }
    // getPlot(){
    //     return this.plot;
    // }
    // resetPlot(){
    //   // console.log('resetPlot')
    //   this.plot = null;
    //     // console.log(this.land)
    // }
    // getLandFromPkinLands(pk){
    //   // console.log('getLandFromPkinLands:'+pk)
    //   if(this.land){
    //     if(pk == this.land.pk){
    //       // console.log("is this land")
    //       // console.log(this.land)
    //       return this.land
    //     }
    //     else{
    //       return null
    //     }
    //   }
    //   else{
    //     return null
    //   }
    // }
    // detectPlot(){
    //     // console.log("detecting plot-----------------------------------------------")
    //     let url = this.router.url;
    //     let chonks = url.split('/');
    //     // console.log(chonks);
    //     chonks = chonks.filter(function (el) {
    //         return el != null && el !=undefined && el != "";
    //     });
    //     if(chonks.length==3 && chonks[0]=='land'){
    //         let pk = chonks[chonks.length-1];
    //         // console.log(this.land_full)
    //         for(let i=0;i<this.land_full['plots'].length;i++){
    //             let plot = this.land_full['plots'][i];
    //             if(plot.pk == pk){
    //               // console.log("IS plot-----------------------------------------------")
    //                 this.setPlot(plot);
    //                 return pk;
    //                 break;
    //             }
    //         }
    //     }
    //     else{
    //       // console.log("detecting NNNNOOOOO plot-----------------------------------------------")
    //       this.plot = null;
    //       this.plotSelected.emit(this.plot);
    //       return null
    //     }
    // }

    changeSomething(what,value){
        this.land[what] = value;
        let token = this.authService.getAuthFromLocalStorage().accessToken;
        let data = {
            token: token,
            pk: this.land.pk,
            what: what,
            value: value
        };
        let httpOptions2 = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':`Bearer ${token}`})
        };
        return this.http.post(`${environment.apiUrl}/api/v1/lands/status-land/${this.land.pk}`, data, httpOptions2).pipe(map(result => {
            if(!result['error']){
                // grab new plot data and adds it to lands
                // console.log("OK")
                // this.landSelected.emit(this.land);
                return true
            }else{
                // console.log("ERROR")
                return false
            }
        }));
    }

    getMaxVigourPlot(){
        if(this.plot == null || this.plot == undefined){
            return 10;
        }else{
            return this.plot.map_max_value;
        }
    }

    getQuantileFiles(){
        if(this.plot == null || this.plot == undefined){
            return null;
        }else{
            // we need the index and date (or file)
            // to get the quantiles file
            return false;
        }
    }
    detectLandPk(){
        // console.log("detecting landpk----------------------------------")
        let url = this.router.url;
        let chonks = url.split('/');
        // console.log(chonks);
        var pk = 'nopk';
        if(chonks.length>=2 && chonks[1]=='land'){
          // console.log('entra dintres')
            pk = chonks[2];
        }
        return pk;
    }
}
