import { EventEmitter, Injectable, Output } from '@angular/core';
import { NavigationEnd, Router, Event, ActivatedRoute } from '@angular/router';

@Injectable()
export class ViewService{
    private view = '';
    private url;
    @Output() viewChanged = new EventEmitter();
    @Output() spinnerChange = new EventEmitter();

    constructor( private router: Router) {
        this.url = this.router.url;
        this.whichView();
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.url=this.router.url;
                this.whichView();
            }
        });

    }

    public whichView(){
        let chonks = this.url.split('/');
        chonks = chonks.filter(function (el) {
            return el != null && el !=undefined && el != "";
        });
        console.log(chonks)
        switch(true){
            case chonks.length==1 && chonks[0]=='land':
                // /land/
                this.view = 'land'
                break;
            case chonks.length==2 && chonks[0]=='land':
                // /land/pk
                this.view = 'landDetail'
                break;
            case chonks.length==3 && chonks[0]=='land':
                // /land/pk/pk2
                this.view = 'plotDetail'
                break;
            case chonks.length==2 && chonks[1]=='my-companies':
                this.view = 'myCompanies'
                break;
            case chonks.length==3 && chonks[1]=='my-companies':
                this.view = 'company'
                break;
            case chonks.length==1 && chonks[0]=='opn':
                // /opn/
                this.view = 'clientsList'
                break;
            case chonks.length==2 && chonks[0]=='opn':
                // /opn/cli
                this.view = 'client'
                break;
            case chonks.length==3 && chonks[0]=='opn':
                // /opn/client/pk
                this.view = 'station'
                break;
            case chonks.length==1 && chonks[0]=='wp':
                // /wp/
                this.view = 'globalPart'
                break;
            case chonks.length==2 && chonks[0]=='wp':
                // /wp/land
                this.view = 'landPart'
                break;
            case chonks.length==3 && chonks[0]=='wp':
                // /wp/land/part
                this.view = 'workPart'
                break;
            case chonks.length==1 && chonks[0]=='wp-admin':
                // /wp-admin/
                this.view = 'adminPart'
                break;
            default:
                this.view = ''
                break;
        }
        this.viewChanged.emit(this.view)
    }

    public getView(){
        return this.view;
    }
    updateSpinner(state:boolean){
        this.spinnerChange.emit(state)
    }
}
