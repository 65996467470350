import { Component, ElementRef, Input, OnInit } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import * as Proj from 'ol/proj';
import { defaults as defaultsControls } from 'ol/control';
import { MapService } from '../map.service';
import { NavigationEnd, Router, Event } from '@angular/router';

export const DETAULT_HEIGHT = '500px'
export const DETAULT_WIDTH = '500px'

@Component({
  selector: 'app-map-wp',
  templateUrl: './map-wp.component.html',
  styleUrls: ['./map-wp.component.scss']
})
export class MapWpComponent implements OnInit {
  @Input() lat: number;
  @Input() lon: number;
  @Input() zoom: number;
  @Input() width: string | number = DETAULT_WIDTH;
  @Input() height: string | number = DETAULT_HEIGHT;

  map: Map;
  private mapEl: HTMLElement;

  constructor(private elementRef: ElementRef, private mapService: MapService, private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.setSize();
      }
  });
  }

  ngOnInit() {
    this.mapEl = this.elementRef.nativeElement.querySelector('#map')
    this.setSize();
  }

  ngAfterViewInit(){
    this.map = new Map({
      target: 'map',
      layers : [
        new TileLayer({
          source: new XYZ({
            url: 'https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoiam9zZXByZW4iLCJhIjoiY2l4dDJhamdmMDAwcjJwdGc1dzd5ZzhvcyJ9._nZdLJgfTXzszURzLAPZvw'
          })
        })
      ],
      view: new View({
        center: Proj.fromLonLat([this.lon,this.lat]),
        zoom: this.zoom
      }),
      controls: defaultsControls().extend([])
    })
    this.mapService.setMap(this.map);
  }

  

  private setSize(){
    if(this.mapEl){
      const styles = this.mapEl.style;
      /* styles.height = cssPixelValue(this.height) || DETAULT_HEIGHT;
      styles.width = cssPixelValue(this.width) || DETAULT_WIDTH; */
      styles.width = cssPixelValue(this.width) || DETAULT_WIDTH;
      //if (this.router.url == '/land/' || this.router.url == '/land' ) {
        styles.height = cssPixelValue(this.height) || DETAULT_HEIGHT;
      /* }else{
        styles.height = '50vh';
      } */
    }
  }

}

const cssUnitsPattern = /([A-Za-z%]+)$/;

function cssPixelValue(value){
  if(value==null){
    return '';
  }

  return cssUnitsPattern.test(value) ? value : `${value}px`;
}