import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GraphOpnService {
  constructor() {}

  cleanValues(val, where, filters) {
    console.log(val);
    console.log(where);
    console.log(filters);
    let final = [];
    let avgs = [];
    let traps = {};
    avgs = this.allWeeksYear();
    let acumulated = [];
    acumulated = this.allWeeksYear();
    for (let i = 0; i < val.length; i++) {
      let station = val[i];
      // console.log(station)
      for (let j = 0; j < station["lectures_setmana"].length; j++) {
        let lectura = station["lectures_setmana"][j];
        // console.log(lectura)
        for (let x = 0; x < filters["feromona"].length; x++) {
          // console.log(filters['feromona'][x])
          for (let way in lectura) {
            // console.log(way)
            // console.log(lectura[way])
            if (way == filters["feromona"][x]) {
              // if (parseInt(lectura["setmana"]) == 11 && parseInt(lectura[way]) > 0 && parseInt(lectura[way]) < 1000) {
              //   console.log(lectura);
              // }
              // console.log(way);
              // console.log(lectura[way]);
              if (lectura[way] != "9999" && filters["year"] == lectura["any"]) {
                let dateAverage2 = parseInt(lectura["setmana"]);
                avgs[dateAverage2 - 1][1] = avgs[dateAverage2 - 1][1] + parseInt(lectura[way]);
                traps[station["nom"]] = lectura["setmana"];
                if (parseInt(lectura[way]) > 0) {
                  if (avgs[dateAverage2 - 1][2]) {
                    avgs[dateAverage2 - 1][2] =
                      avgs[dateAverage2 - 1][2] +
                      ",\n<br>" +
                      station["nom"] +
                      "(" +
                      station["waypoint"] +
                      ")--" +
                      parseInt(lectura[way]);
                  } else {
                    avgs[dateAverage2 - 1][2] = station["nom"] + "(" + station["waypoint"] + ")--" + parseInt(lectura[way]);
                  }
                }
                // avgs.push([dateAverage2, parseInt(lectura[way])]);
                // acumulated.push([dateAverage2, parseInt(lectura[way])]);
                acumulated[dateAverage2 - 1][1] = acumulated[dateAverage2 - 1][1] + parseInt(lectura[way]);
              }
            }
          }
        }
      }
    }
    let new_acumulated = [];
    for (let i = 0; i < acumulated.length; i++) {
      if (i == 0) {
        new_acumulated[i] = acumulated[i];
      } else {
        new_acumulated[i] = [acumulated[i][0], new_acumulated[i - 1][1] + acumulated[i][1]];
      }
    }
    // console.log(acumulated)
    final.push(avgs);
    final.push(new_acumulated);
    console.log(final);
    // for(let j=0;j<final[0].length;j++){
    //   //console.log(values2017[i]);
    //   if(j==0){
    //     final[0][j][2]=0;
    //   }else{
    //     final[0][j][2] = Math.floor(((final[0][j][1]-final[0][j-1][1])/final[0][j-1][1])*100);
    //   }
    // }
    // let first_file=new Date().getTime()
    // let last_file=new Date(0).getTime()
    // for(let k=0;k<final[0].length;k++){
    //   let date = final[0][k][0]
    //   if(date < first_file){
    //     first_file = final[0][k][0]
    //   }
    //   if(date > last_file){
    //     last_file = final[0][k][0]
    //   }
    // }
    return final;
  }
  allWeeksYear() {
    let arry = [];
    for (let i = 0; i < 52; i++) {
      arry[i] = [i + 1, 0];
    }
    return arry;
  }
}
