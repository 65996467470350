import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { LandModel } from './land.model';
import { map, catchError, switchMap, finalize, first } from 'rxjs/operators';
import { AuthService } from '../../modules/auth/_services/auth.service';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserModel } from 'src/app/modules/auth';
import { Company } from '../companies/company.model';
import { Plot } from './lands/plots/plot.model';
import { LandListService } from './lands/land-list/land-list.service';
import { LandDetailService } from './lands/land-detail/land-detail.service';
import { LandActionsService } from './lands/land-detail/land-actions/land-actions.service';
import { LandFile } from './lands/plots/files/file.model';
import { Router } from '@angular/router';
var clone = require('clone');

@Injectable()
export class LandsService {
    private lands:LandModel[] = [];
    private land:LandModel;
    private land_full:LandModel;
    private plot:Plot;
    private n:number=0;
    @Output() landsModified = new EventEmitter();
    @Output() landSelected = new EventEmitter();
    @Output() plotSelected = new EventEmitter();

    constructor(private http: HttpClient,private  authService: AuthService, private landDetailService: LandDetailService, private landListService: LandListService, private landActionsService: LandActionsService, private router: Router) { }

    setLands(lands:LandModel[]){
        this.lands = lands;
    }
    setLand(land:LandModel){
        this.land = land;
        this.land_full = clone(this.land);
    }
    emitFromStation(){
      this.landSelected.emit(this.land);
    }
    resetLand(){
      // console.log('reset landaaa')
        this.land = clone(this.land_full);
        // console.log(this.land)
    }
    getLands(){
        return this.lands;
    }
    resumeSavedLands(){
      this.landsModified.emit(this.lands);
    }
    requestLands(){
        // console.log("aqui entra? Land Service request lands")
        let token = this.authService.getAuthFromLocalStorage().accessToken;
        let data = {
            token: token,
            start: this.n,
            finish: this.n +3
        };
        let httpOptions2 = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':`Bearer ${token}`})
        };
        return this.http.post(`${environment.apiUrl}/api/v1/lands/list`, data, httpOptions2).pipe(map(result => {
            if(!result['error']){
                this.lands=[];
                for(let i=0;i<result['data'].length;i++){
                    let entry = result['data'][i];
                    //let variation = JSON.parse(entry['ndvi'])

                    let land = new LandModel;
                    land.pk = entry['land_id'];
                    land.name = entry['land_name'];
                    land.description = entry['land_description'];
                    land.idExternal = entry['land_external_id'];
                    land.owner = new UserModel;
                    land.owner.fullname = entry['owner'];
                    land.company = new Company;
                    land.company.name = entry['company'];
                    let area;
                    if(entry['area'] == null || entry['area'] == undefined || entry['area'] == ''){
                        area = 0;
                    }else{
                        area = +parseFloat(entry['area']).toFixed( 2 );
                    }
                    land.area = area;
                    land.active = entry['active'];
                    land.midSeason = entry['midSeason'];
                    land.packStress = entry['packStress'];
                    land.satellite = entry['satellite']
                    land.summer = entry['summer'];
                    land.winter = entry['winter']
                    this.lands.push(land);
                }
                this.n = this.n + 20
                this.landsModified.emit(this.lands);
            }
            return result
        }));
    }

    requestPlotsOverview(){
        let token = this.authService.getAuthFromLocalStorage().accessToken;
        let data = {
            token: token
        };
        let httpOptions2 = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':`Bearer ${token}`})
        };
        return this.http.get(`${environment.apiUrl}/api/v1/lands/plots-overview`, httpOptions2).pipe(map(result => {
            if(!result['error']){
                // grab new plot data and adds it to lands
                for(let i=0;i<result['data'].length;i++){
                    let entry = result['data'][i];
                    for(let z=0;z<this.lands.length;z++){
                        if(this.lands[z].pk == entry['id']){
                            this.lands[z].plots = []
                            for(let j=0;j<entry['plots'].length;j++){
                                let plot = new Plot;
                                plot.pk = entry['plots'][j]['pk'];
                                plot.name = entry['plots'][j]['name'];
                                plot.coordinates = [];
                                for(let x=0;x<entry['plots'][j]['coordinates'].length;x++){
                                    plot.coordinates.push(entry['plots'][j]['coordinates'][x])
                                }
                                this.lands[z].plots.push(plot);
                            }
                            break;
                        }
                    }
                }
                this.landsModified.emit(this.lands);
            }
            return result
        }));
    }

    requestLandInfo(pk){
        // console.log("requestLandInfo")
        this.land = this.landDetailService.getLandInfo(pk,this.lands);
        this.landSelected.emit(this.land);
        return this.land;
    }
    returnLandExists(){
      this.landSelected.emit(this.land);
    }
    getLand(){
        return this.land;
    }
    getLandInitial(){
        return this.land_full;
    }
    saveLandWeather(weath,where){
      if(where == 'plots'){
        this.land.weather_now = weath['weather_now'];
        this.land.weather_prev= weath['weather_5days'];
        this.land.weather_hist= weath['weather_hist'];
      }
    }
    requestLandFromBd(pk) {
        return new Promise((resolve, reject) => {
            // console.log("request land from db")
            this.landDetailService.getLandInfoBd(pk).pipe(first())
                .subscribe(
                    data => {
                        // console.log(data['data'])
                        data = data['data']
                        if (data['plots'] != undefined && data['plots'] != null) {
                            // console.log("entra a data plots")
                            for (let i = 0; i < data['plots'].length; i++) {
                                let plotdata = data['plots'][i];
                                for (let j = 0; j < this.land['plots'].length; j++) {
                                    let plot = this.land['plots'][j];
                                    if (plot.pk == plotdata.pk) {
                                        plot.active = plotdata.active;
                                        plot.area = plotdata.area;
                                        plot.description = plotdata.description;
                                        plot.id_external = plotdata.id_external;
                                        plot.id_land_api = plotdata.id_land_api;
                                        plot.id_user_api = plotdata.id_user_api;
                                        plot.map_max_value = plotdata.map_max_value;
                                        plot.map_min_value = plotdata.map_min_value;
                                        plot.opnat = plotdata.opnat;
                                        plot.place = plotdata.place;
                                        plot.planet = plotdata.planet;
                                        plot.points = plotdata.points;
                                        plot.producer = plotdata.producer;
                                        plot.quality = plotdata.quality;
                                        plot.type = plotdata.type;
                                        plot.coordinates = [];
                                        for(let x=0;x<plotdata['coordinates'].length;x++){
                                            plot.coordinates.push(plotdata['coordinates'][x])
                                        }
                                        plot.files = []
                                        for (let l = 0; l < plotdata['files'].length; l++) {
                                            let filedata = plotdata['files'][l]
                                            let newfile = new LandFile;
                                            newfile.classes = filedata.classes
                                            newfile.Date_timeline = new Date(filedata.date_timeline)
                                            newfile.extra_average = filedata.extra_average
                                            newfile.extra_info = filedata.extra_info
                                            newfile.extra_max_value = filedata.extra_max_value
                                            newfile.extra_min_value = filedata.extra_min_value
                                            newfile.extra_std = filedata.extra_std
                                            newfile.location = filedata.location
                                            newfile.name = filedata.name
                                            newfile.pk = filedata.pk
                                            newfile.colors = filedata.colors
                                            newfile.source = filedata.source
                                            newfile.type = filedata.type
                                            plot.files.push(newfile)
                                        }
                                        break;
                                    }
                                }
                            }
                            // console.log(this.land)
                            this.landSelected.emit(this.land)
                            resolve(true)
                        }
                        else{
                          reject(false)
                        }
                    },
                    error => {
                        reject(false)
                    }
                );

        });
    }
    requestLandFromBdURL(pk) {
        return new Promise((resolve, reject) => {
            // console.log("request land from db url")
            this.landDetailService.getLandInfoBd(pk).pipe(first())
                .subscribe(
                    data => {
                        // console.log(data['data'])
                        data = data['data']
                        if (data['plots'] != undefined && data['plots'] != null) {
                            // console.log("entra a data plots")
                            this.land = new LandModel;
                            this.land.pk = data['land_id'];
                            this.land.name = data['land_name']
                            this.land.description = data['land_description'];
                            this.land.idExternal = data['land_external_id'];
                            this.land.owner = new UserModel;
                            this.land.owner.fullname = data['owner'];
                            this.land.company = new Company;
                            this.land.company.name = data['company'];
                            let area;
                            if(data['area'] == null || data['area'] == undefined || data['area'] == ''){
                                area = 0;
                            }else{
                                area = +parseFloat(data['area']).toFixed( 2 );
                            }
                            this.land.area = area;
                            this.land.active = data['active'];
                            this.land.midSeason = data['midSeason'];
                            this.land.packStress = data['packStress'];
                            this.land.satellite = data['satellite']
                            this.land.summer = data['summer'];
                            this.land.winter = data['winter']
                            this.land.plots = [];
                            for (let i = 0; i < data['plots'].length; i++) {
                                let plotdata = data['plots'][i];
                                // console.log(plotdata)
                                let plot = new Plot;
                                // this.land['plots'][j];
                                plot.pk = plotdata.pk;
                                plot.active = plotdata.active;
                                plot.area = plotdata.area;
                                plot.name = plotdata.name;
                                plot.description = plotdata.description;
                                plot.id_external = plotdata.id_external;
                                plot.id_land_api = plotdata.id_land_api;
                                plot.id_user_api = plotdata.id_user_api;
                                plot.map_max_value = plotdata.map_max_value;
                                plot.map_min_value = plotdata.map_min_value;
                                plot.opnat = plotdata.opnat;
                                plot.place = plotdata.place;
                                plot.planet = plotdata.planet;
                                plot.points = plotdata.points;
                                plot.producer = plotdata.producer;
                                plot.quality = plotdata.quality;
                                plot.type = plotdata.type;
                                plot.coordinates = [];
                                for(let x=0;x<plotdata['coordinates'].length;x++){
                                    plot.coordinates.push(plotdata['coordinates'][x])
                                }
                                plot.files = []
                                for (let l = 0; l < plotdata['files'].length; l++) {
                                    let filedata = plotdata['files'][l]
                                    let newfile = new LandFile;
                                    newfile.classes = filedata.classes
                                    newfile.Date_timeline = new Date(filedata.date_timeline)
                                    newfile.extra_average = filedata.extra_average
                                    newfile.extra_info = filedata.extra_info
                                    newfile.extra_max_value = filedata.extra_max_value
                                    newfile.extra_min_value = filedata.extra_min_value
                                    newfile.extra_std = filedata.extra_std
                                    newfile.location = filedata.location
                                    newfile.name = filedata.name
                                    newfile.pk = filedata.pk
                                    newfile.colors = filedata.colors
                                    newfile.source = filedata.source
                                    newfile.type = filedata.type
                                    plot.files.push(newfile)
                                }
                                // console.log(plot)
                                this.land.plots.push(plot)
                            }
                            // console.log(this.land)
                            this.land_full = clone(this.land);
                            this.landSelected.emit(this.land)
                            resolve(true)
                        }
                        else{
                          reject(false)
                        }
                    },
                    error => {
                        reject(false)
                    }
                );

        });
    }
    setPlot(plot: Plot){
        this.plot = plot;
        this.plotSelected.emit(this.plot);
    }
    getPlot(){
        return this.plot;
    }
    resetPlot(){
      // console.log('resetPlot')
      this.plot = null;
        // console.log(this.land)
    }
    getLandFromPkinLands(pk){
      // console.log('getLandFromPkinLands:'+pk)
      if(this.land){
        if(pk == this.land.pk){
          // console.log("is this land")
          // console.log(this.land)
          return this.land
        }
        else{
          return null
        }
      }
      else{
        return null
      }
    }
    detectPlot(){
        // console.log("detecting plot-----------------------------------------------")
        let url = this.router.url;
        let chonks = url.split('/');
        // console.log(chonks);
        chonks = chonks.filter(function (el) {
            return el != null && el !=undefined && el != "";
        });
        if(chonks.length==3 && chonks[0]=='land'){
            let pk = chonks[chonks.length-1];
            // console.log(this.land_full)
            for(let i=0;i<this.land_full['plots'].length;i++){
                let plot = this.land_full['plots'][i];
                if(plot.pk == pk){
                  // console.log("IS plot-----------------------------------------------")
                    this.setPlot(plot);
                    return pk;
                    break;
                }
            }
        }
        else{
          // console.log("detecting NNNNOOOOO plot-----------------------------------------------")
          this.plot = null;
          this.plotSelected.emit(this.plot);
          return null
        }
    }

    changeSomething(what,value){
        this.land[what] = value;
        let token = this.authService.getAuthFromLocalStorage().accessToken;
        let data = {
            token: token,
            pk: this.land.pk,
            what: what,
            value: value
        };
        let httpOptions2 = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':`Bearer ${token}`})
        };
        return this.http.post(`${environment.apiUrl}/api/v1/lands/status-land/${this.land.pk}`, data, httpOptions2).pipe(map(result => {
            if(!result['error']){
                // grab new plot data and adds it to lands
                // console.log("OK")
                this.landSelected.emit(this.land);
                return true
            }else{
                // console.log("ERROR")
                return false
            }
        }));
    }

    getMaxVigourPlot(){
        if(this.plot == null || this.plot == undefined){
            return 10;
        }else{
            return this.plot.map_max_value;
        }
    }

    getQuantileFiles(){
        if(this.plot == null || this.plot == undefined){
            return null;
        }else{
            // we need the index and date (or file)
            // to get the quantiles file
            return false;
        }
    }
    saveFileNewLand(file){
      return this.landActionsService.saveFileNewLandActions(file);
    }
    getFileFromUrl(url){
      return this.landActionsService.getFileFromUrlLandActions(url);
    }
    createLand(form,url,fields){
      return this.landActionsService.createLandActions(form,url,'0',fields);
    }
    detectLandPk(){
        // console.log("detecting landpk----------------------------------")
        let url = this.router.url;
        let chonks = url.split('/');
        // console.log(chonks);
        var pk = 'nopk';
        if(chonks.length>=2 && chonks[1]=='land'){
          // console.log('entra dintres')
            pk = chonks[2];
        }
        return pk;
    }
}
