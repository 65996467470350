import { Injectable } from "@angular/core";
import { Style, Fill, Stroke, Circle, Icon, Text } from 'ol/style';
import Map from 'ol/Map';
import Feature from 'ol/Feature';


@Injectable()
export class AverageVariationStyleService{
    constructor(){}

    colors(flag,value){
        let color;
        switch (true) {
            case (flag && value < 0.1 && value >= 0):
                color = 'rgba(158,1,66,0.7)';
                break;
            case (flag && value < 0.2 && value >= 0.1) || (!flag && value > 25):
                color = 'rgba(213,62,79,0.7)';
                break;
            case (flag && value < 0.3 && value >= 0.2):
                color = 'rgba(244,109,67,0.7)';
                break;
            case (flag && value < 0.4 && value >= 0.3):
                color = 'rgba(253,174,97,0.7)';
                break;
            case (flag && value < 0.5 && value >= 0.4) || (!flag && value <= 25 && value > 15):
                color = 'rgba(254,224,139,0.7)';
                break;
            case (flag && value < 0.6 && value >= 0.5):
                color = 'rgba(230,245,152,0.7)';
                break;
            case (flag && value < 0.7 && value >= 0.6) || (!flag && value <= 15 && value > 5):
                color = 'rgba(171,221,164,0.7)';
                break;
            case (flag && value < 0.8 && value >= 0.7):
                color = 'rgba(102,194,165,0.7)'
                break;
            case (flag && value < 0.9 && value >= 0.8):
                color = 'rgba(50,136,189,0.7)'
                break;
            case (flag && value <= 1 && value >= 0.9) || (!flag && value <= 5 && value > 0):
                color = 'rgba(94,79,162,0.7)';
                break;
            default:
                color = 'rgba(255,255,255,0)';
                break;
        }
        return color;
    }

    blackorwhite(hex) {
        let r, g, b;
        if (hex.indexOf('#') === 0) {
          hex = hex.slice(1);
          // convert 3-digit hex to 6-digits.
          if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
          }
          if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
          }
          r = parseInt(hex.slice(0, 2), 16);
          g = parseInt(hex.slice(2, 4), 16);
          b = parseInt(hex.slice(4, 6), 16);
          // http://stackoverflow.com/a/3943023/112731
        } else {
          let prep = hex.substr(5);
          prep = prep.substring(0, prep.length - 1);
          prep = prep.split(',')
          r = parseInt(prep[0]);
          g = parseInt(prep[1]);
          b = parseInt(prep[2]);
        }
        if ((r * 0.299 + g * 0.587 + b * 0.114) > 186) {
          return '#000000';
        } else {
          return '#FFFFFF';
        }
      }

    getStyle(resolution,f:Feature,flag,value,textValues){
        var text = resolution < 15 ? f.get('plotName') : '';
        if(textValues!=''){
            if (text == "") {
                text = textValues;
            } else {
                text = text + " | " + textValues;
            }
        }
        let color = this.colors(flag,value)
        return new Style({
            stroke: new Stroke({
                color: '#000000',
                width: 1
            }),
            fill: new Fill({
                color: color
            }),
            text: new Text({
                text: text,
                textAlign: 'center',
                offsetY: 0,
                scale: 1.1,
                fill: new Fill({
                    color: this.blackorwhite(color)
                })
            })
        });
    }

    getAverageStyle(resolution, f: Feature) {
        let textValues = '';
        let avg;
        // console.log(f.get('average'))
        if (f.get('average')) {
            avg = f.get('average');
            if (avg >= 0) {
                textValues = avg.toFixed(3) + " avg";
            }
        }
        return this.getStyle(resolution,f,true,avg,textValues);
    }

    getVariationStyle(resolution, f: Feature){
        let textValues='';
        let value;
        if (f.get('average') && f.get('variation')) {
            let avg = f.get('average');
            let variation = f.get('variation');
            if (variation >= 0) {
                value = (variation/avg)*100
                textValues=((variation/avg)*100).toFixed(3)+"%";
            }
        }
        return this.getStyle(resolution,f,false,value,textValues);
    }

}
