import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { AuthService } from "src/app/modules/auth";
import { environment } from "src/environments/environment";
import { LandModel } from '../../../land.model';
import { LandsService } from '../../../lands.service';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  landId = '';
  auxiliar={};

  constructor(private landService: LandsService, private authService: AuthService, private http: HttpClient) {

  }
  getWeather(){
    /* let land:LandModel = this.landService.getLand()
    this.landId = land.pk */
    // aqui hauria d'haver el post al server per agafar el weather
    // let test = `{
    //   "today":{
    //     "day": ${new Date().getDate()},
    //     "month": "${new Date().toLocaleString('default', { month: 'long' })}",
    //     "icon": "clear-day",
    //     "temp": 23,
    //     "rain": 0,
    //     "wind": 3.0,
    //     "eto": 6.66
    //   },
    //   "forecasts": [{"time": 1652313600.0, "temperatureHigh": 27.0, "temperatureLow": 13.0, "temperatureAvg": 19.541666666666668, "precipProbability": 0.0, "windSpeed": 6.0, "windGust": 4.125, "icon": "cloudy", "eto": 0.31733721269384246, "cloudCov": 65.83333333333333, "humidity": 48.916666666666664, "uvIndex": 1.5833333333333333}, {"time": 1652400000.0, "temperatureHigh": 29.0, "temperatureLow": 12.0, "temperatureAvg": 20.458333333333332, "precipProbability": 0.0, "windSpeed": 5.0, "windGust": 2.9166666666666665, "icon": "partly-cloudy-day", "eto": 0.3491705262808313, "cloudCov": 30.458333333333332, "humidity": 45.458333333333336, "uvIndex": 2.0}, {"time": 1652486400.0, "temperatureHigh": 30.0, "temperatureLow": 14.0, "temperatureAvg": 22.0, "precipProbability": 0.0, "windSpeed": 6.0, "windGust": 3.4583333333333335, "icon": "rain", "eto": 0.35814579696528676, "cloudCov": 61.583333333333336, "humidity": 42.333333333333336, "uvIndex": 1.6666666666666667}, {"time": 1652572800.0, "temperatureHigh": 30.0, "temperatureLow": 16.0, "temperatureAvg": 22.625, "precipProbability": 0.0, "windSpeed": 6.0, "windGust": 3.5416666666666665, "icon": "clear-day", "eto": 0.3683199752766883, "cloudCov": 46.958333333333336, "humidity": 41.375, "uvIndex": 2.0}, {"time": 1652659200.0, "temperatureHigh": 33.0, "temperatureLow": 16.0, "temperatureAvg": 23.5, "precipProbability": 0.0, "windSpeed": 5.0, "windGust": 3.0416666666666665, "icon": "sleet", "eto": 0.37237604121548323, "cloudCov": 31.541666666666668, "humidity": 42.708333333333336, "uvIndex": 2.0}, {"time": 1652745600.0, "temperatureHigh": 33.0, "temperatureLow": 16.0, "temperatureAvg": 24.458333333333332, "precipProbability": 0.0, "windSpeed": 5.0, "windGust": 3.25, "icon": "snow", "eto": 0.39983157065998154, "cloudCov": 18.583333333333332, "humidity": 42.041666666666664, "uvIndex": 2.0833333333333335}]}`
    //
    // return JSON.parse(test);
    let landii = this.landService.getLand();
    // console.log(landii)
    let token = this.authService.getAuthFromLocalStorage().accessToken;
    let data = {
        token: token,
        land_pk: landii.pk
    };
    // console.log(data)
    let httpOptions2 = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':`Bearer ${token}`})
    };
    return this.http.get(`${environment.apiUrl}/api/v1/lands/get-weather/${landii.pk}`, httpOptions2).pipe(map(result => {
      // console.log(result)
        if(!result['error']){
            return result;
        }
    }))

  }
  eotFunctionHist(latValSt,plot_values_extra){
    // console.log("["+latValSt+ "]");
    let latVal = []
    if(latValSt.includes('],')){
      latVal = JSON.parse("["+latValSt+ "]")
    }
    else{
      latVal = JSON.parse(latValSt)
    }
    let eotVal = [];
    let julian = [];
    let latAux = this.toDegreesMinutesAndSeconds(latVal[1]);
    // console.log(latAux);
    let latRad = (Math.PI/180) * latAux;
    // console.log(latRad);
    for(var li=0;li<(plot_values_extra[0]).length;li++){
      // // console.log('Times');
      // // console.log(toJulianDate(new Date(temps[li][0])));
      // julian[li] = this.toJulianDate(new Date(plot_values_extra[0][li][0]));
      // let dr = 1 + (0.033 * Math.cos((2*Math.PI / 365)*julian[li]));
      // // console.log(dr);
      // let ds = 0.409 * Math.sin(2*(Math.PI*julian[li] / 365)-1.39);
      // // console.log(ds);
      // let ws = Math.acos(-1*Math.tan(latRad)*Math.tan(ds));
      // // console.log(Math.tan(latRad));
      // // console.log(Math.acos(Math.tan(latRad)*Math.tan(ds)));
      // // console.log(ws);
      // let ra = (24*60/Math.PI)*0.082*dr*(ws*Math.sin(latRad)*Math.sin(ds)+Math.cos(latRad)*Math.cos(ds)*Math.sin(ws));
      // // console.log(ra);
      // let ram = ra * 0.408;
      // let evohav = 0.0023*(plot_values_extra[0][li][1]+17.78)*ram*(Math.pow(10, 0.5));
      // // console.log(evohav);
      // eotVal[li]=[];
      // eotVal[li][0] = plot_values_extra[0][li][0];
      // eotVal[li][1] = parseFloat(evohav.toFixed(3));



      // console.log('Times');
      // console.log(toJulianDate(new Date(temps[li][0])));
      julian[li] = this.toJulianDate(new Date(plot_values_extra[0][li][0]));
      var dr = 1 + (0.033 * Math.cos((2*Math.PI / 365)*julian[li]));
      // console.log(dr);
      var ds = 0.409 * Math.sin(2*(Math.PI*julian[li] / 365)-1.39);
      // console.log(ds);
      var ws = Math.acos(-1*Math.tan(latRad)*Math.tan(ds));
      // console.log(Math.tan(latRad));
      // console.log(Math.acos(Math.tan(latRad)*Math.tan(ds)));
      // console.log(ws);
      var ra = (24*60/Math.PI)*0.082*dr*(ws*Math.sin(latRad)*Math.sin(ds)+Math.cos(latRad)*Math.cos(ds)*Math.sin(ws));
      var nM = (24/Math.PI)*ws;
      var inm = plot_values_extra[0][li][2]*1.4;
      var nR = nM*(1-inm);
      var fS = nR/nM;
      var rS = (0.25+(0.5*fS))*ra;
      var facC=1;
      if(plot_values_extra[0][li][3]<50){
        facC = 1+((50 - plot_values_extra[0][li][3])/70);
      }
      var rG = ra*(0.19+(0.55*fS))
      var etotmod = (0.31*facC*(rG+2.094)*plot_values_extra[0][li][1])/(plot_values_extra[0][li][1]+15)
      var etotmodv2 = etotmod;
      // if(dataForec[li].temperatureLow<=0){
      //   etotmodv2 = etotmod*1.5;
      // }
      // console.log(ra);
      var ram = ra * 0.408;
      var evohav = 0.0023*(plot_values_extra[0][li][1]+17.78)*ram*(Math.pow(10, 0.5));
      // console.log(evohav);
      eotVal[li]=[];
      eotVal[li][0] = plot_values_extra[0][li][0];
      eotVal[li][1] = parseFloat(etotmodv2.toFixed(3));
    }
    return eotVal
    // console.log('ETOOOOOSSS');
    // console.log(eotVal);
  }
  toJulianDate(valu){
    var j=Math.floor((valu.getTime()-new Date('Dec 30,'+(valu.getFullYear()-1)+' 23:00:00').getTime())/86400000);
    // console.log(j)
    // let i=3-j.length;
    // while(i-->0)j=0+j;
    return j;
  }
  toDegreesMinutesAndSeconds(coordinate) {
      var absolute = Math.abs(coordinate);
      var degrees = Math.floor(absolute);
      var minutesNotTruncated = (absolute - degrees) * 60;
      var minutes = Math.floor(minutesNotTruncated);
      var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

      return degrees;
  }
  eotFunctionFuture(latVal,dataNo){
    var dataForec = dataNo;
    // console.log(dataForec)
    let julian = [];
    var latAux = this.toDegreesMinutesAndSeconds(latVal);
    // console.log(latAux);
    var latRad = (Math.PI/180) * latAux;
    // console.log(latRad);
    for(var li=0;li<dataForec.length;li++){
      // console.log('Times');
      // console.log(toJulianDate(new Date(temps[li][0])));
      let tims = dataForec[li].time*1000;
      julian[li] = this.toJulianDate(new Date(tims));
      var dr = 1 + (0.033 * Math.cos((2*Math.PI / 365)*julian[li]));
      // console.log(dr);
      var ds = 0.409 * Math.sin(2*(Math.PI*julian[li] / 365)-1.39);
      // console.log(ds);
      var ws = Math.acos(-1*Math.tan(latRad)*Math.tan(ds));
      // console.log(Math.tan(latRad));
      // console.log(Math.acos(Math.tan(latRad)*Math.tan(ds)));
      // console.log(ws);
      var ra = (24*60/Math.PI)*0.082*dr*(ws*Math.sin(latRad)*Math.sin(ds)+Math.cos(latRad)*Math.cos(ds)*Math.sin(ws));
      var nM = (24/Math.PI)*ws;
      var inm = 0;
      if((dataForec[li].cloudCov/100)>0.25){
        inm = dataForec[li].cloudCov/100;
      }
      // console.log(inm);
      var nR = nM*(1-inm);
      var fS = nR/nM;
      var rS = (0.25+(0.5*fS))*ra;
      var facC=1;
      if(dataForec[li].humidity<50){
        facC = 1+((50 - dataForec[li].humidity)/70);
      }
      // console.log(facC);
      var rG = ra*(0.19+(0.55*fS))
      // console.log(rG);
      var etotmod = 0;
      if(dataForec.length == 1){
        etotmod = (0.31*facC*(rG+2.094)*dataForec[li].temperature)/(dataForec[li].temperature+15)
      }
      else{
        etotmod = (0.31*facC*(rG+2.094)*dataForec[li].temperatureAvg)/(dataForec[li].temperatureAvg+15)
      }
      // console.log(etotmod);
      var etotmodv2 = etotmod;
      if(dataForec[li].temperatureLow<=0){
        etotmodv2 = etotmod*1.5;
      }
      // eotValFut[li] = parseFloat(etotmodv2.toFixed(3));
      dataForec[li]['etotmodv2'] = parseFloat(etotmodv2.toFixed(3));

      let etmin = 0.6198 * Math.exp((17.27*dataForec[li].temperatureLow)/(dataForec[li].temperatureLow+237.3));
      let etmax = 0.6198 * Math.exp((17.27*dataForec[li].temperatureHigh)/(dataForec[li].temperatureHigh+237.3));
      let avgetsat = (etmax + etmin) / 2;
      let revpres = ((etmin * dataForec[li].humMax/100) + (etmax * dataForec[li].humMin/100))/2;
      let vpd = avgetsat - revpres;
      // console.log(dr);

      // eotValFut[li] = parseFloat(etotmodv2.toFixed(3));
      dataForec[li]['vpd'] = parseFloat(vpd.toFixed(3));
    }
    return dataForec;
    // newWeather();
  }
}
