import { ChangeDetectorRef, Component, ElementRef, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { GraphsService } from './graphs.service';
import { ViewService } from 'src/app/estratos-components/global/view.service';

@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.scss']
})
export class GraphsComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    exporting: {
      scale: 1,
      sourceWidth: 1500,
      sourceHeight: 500
    },
    chart: {
        zoomType: 'xy'
    },
    title: {
        text: 'Plot',
        style:{
          color: '#333333',
          fontSize: '15px'
        }
    },
    subtitle: {
        text: 'E-STRATOS'
    },
    xAxis: [],
    yAxis: [],
    series: [],
    legend: {
      layout: 'vertical',
      align: 'left',
      x: 10,
      verticalAlign: 'top',
      y: 10,
      floating: false,
      backgroundColor: '#FFFFFF'
    }
  };
  updateFlag = false;
  plot_values=[];
  plot_values_extra=[];
  // plot_values_1
  // plot_values_2=[];
  @Input() array_values:[];
  @Input() extra_values:{};
  @Input() title:string;
  @Input() subtitle:string;
  @Input() where:string;
  constructor(private graphsService: GraphsService, private viewService: ViewService, private ref: ChangeDetectorRef) {
    // this.viewService.viewChanged.subscribe(
    //   view => {
    //     // this.view = view;
    //     console.log('VIEW CHANGED GRPAHS');
    //     this.chartOptions.title.text = this.title
    //     this.chartOptions.subtitle.text = this.subtitle
    //     this.printPlot('initial');
    //     // console.log(this.updateFlag);
    //     // if(this.updateFlag === true){
    //     //   this.chartOptions.title.text = this.title
    //     //   this.chartOptions.subtitle.text = this.subtitle
    //     //   this.printPlot('initial');
    //     // }
    //     this.ref.detectChanges();
    //   }
    // )
    // this.graphsService.viewChanged.subscribe(
    //   view => {
    //     // this.view = view;
    //     console.log('VIEW CHANGED GRPAHS');
    //     this.chartOptions.title.text = this.title
    //     this.chartOptions.subtitle.text = this.subtitle
    //     this.printPlot('initial');
    //     // console.log(this.updateFlag);
    //     // if(this.updateFlag === true){
    //     //   this.chartOptions.title.text = this.title
    //     //   this.chartOptions.subtitle.text = this.subtitle
    //     //   this.printPlot('initial');
    //     // }
    //     this.ref.detectChanges();
    //   }
    // )
  }

  ngOnInit(): void {
    // this.chartOptions.series[0] = {
    //   type: 'line',
    //   data: this.data.reverse()
    // }
    // console.log(this.array_values);
    // console.log(this.extra_values);
    console.log(this.where);
    this.updateFlag = true;
    this.chartOptions.title.text = this.title
    this.chartOptions.subtitle.text = this.subtitle
    if(this.where == 'PlotFiles'){
      // this.cleanValuesPlotFiles()
      this.printPlot('initial');
    }
    else{
      this.printPlot('initial');
    }
  }
  printPlot(whereabout){
    console.log('PRINT PLOTS GRAPHS')
    console.log(this.where)
    // this.chartOptions.xAxis = [{type: "datetime"}];
    // this.chartOptions.xAxis = this.getAxisX();
    // this.chartOptions.yAxis = this.getAxisY();
    if(this.where == 'PlotFiles'){
      this.chartOptions.xAxis = this.graphsService.getAxisXPlotFiles(this.where);
      this.chartOptions.yAxis = this.graphsService.getAxisYPlotFiles(this.where);
      this.plot_values = this.graphsService.cleanValuesPlot(this.array_values);
      // marc pixel
      this.plot_values_extra = this.graphsService.cleanValuesWeather(this.extra_values);
      this.chartOptions.series = this.graphsService.getSeriesPlot(this.plot_values, this.plot_values_extra,'PlotFiles');
      // marc pixel
      console.log(this.plot_values[1])
    }
    if(this.where == 'PlotFilesYear'){
      this.chartOptions.xAxis = this.graphsService.getAxisXPlotFiles(this.where);
      this.chartOptions.yAxis = this.graphsService.getAxisYPlotFiles(this.where);
      this.plot_values = this.graphsService.cleanValuesPlotYear(this.array_values);
      // this.plot_values_extra = this.graphsService.cleanValuesWeather(this.extra_values);
      this.chartOptions.series = this.graphsService.getSeriesPlot(this.plot_values, this.plot_values_extra,'PlotFilesYear');
      console.log(this.plot_values[1])
    }
    if(this.where == 'LandAnalytics'){
      this.chartOptions.xAxis = this.graphsService.getAxisXAnalyticsLand();
      this.chartOptions.yAxis = this.graphsService.getAxisYAnalyticsLand();
      this.plot_values = this.graphsService.cleanValuesAnalytics(this.array_values);
      this.chartOptions.series = this.graphsService.getSeriesPlot(this.plot_values, this.plot_values_extra,'LandAnalytics');
    }
    if(this.where == 'OPNClient1' || this.where == 'OPNClient2' || this.where == 'OPNStation1' || this.where == 'OPNStation2'){
      this.chartOptions.xAxis = this.graphsService.getAxisXOPNAnalytics();
      this.chartOptions.yAxis = this.graphsService.getAxisYOPNAnalytics();
      this.plot_values = this.graphsService.cleanValuesOPNAnalytics(this.array_values,this.where,this.extra_values);
      this.chartOptions.series = this.graphsService.getSeriesOPNAnalytics(this.plot_values, this.where,this.extra_values);
      // console.log()
    }
    // if(this.where == 'OPNStation1' || this.where == 'OPNStation1'){
    //   this.chartOptions.xAxis = this.graphsService.getAxisXOPNStation(this.where);
    //   this.chartOptions.yAxis = this.graphsService.getAxisYOPNStation(this.where);
    // }
  }
}
