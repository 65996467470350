<!-- <ng-container
  *ngIf="active; then active; else inactive">
</ng-container>

<ng-template #active>
    <div class="form-group mb-0 row align-items-center">
        <label class="col-8 col-form-label">Land active:</label>
        <div class="col-4 d-flex justify-content-end">
          <span class="switch switch-success switch-sm">
            <label>
              <input type="checkbox" checked="checked" name="select" (change)="toggleActive()" />
              <span></span>
            </label>
          </span>
        </div>
    </div>
</ng-template>
<ng-template #inactive>
  <div class="form-group mb-0 row align-items-center">
    <label class="col-8 col-form-label">Land inactive:</label>
    <div class="col-4 d-flex justify-content-end">
      <span class="switch switch-success switch-sm">
        <label>
          <input type="checkbox" name="select" (change)="toggleActive()" />
          <span></span>
        </label>
      </span>
    </div>
</div>
</ng-template> -->

<div class="form-group mb-0 row align-items-center">
    <label class="col-8 col-form-label">Land active:</label>
    <div class="col-4 d-flex justify-content-end">
      <span class="switch switch-sm switch-primary">
        <label>
          <input type="checkbox" [checked]="land.active" name="selectActive" (change)="toggleActive()" />
          <span></span>
        </label>
      </span>
    </div>
</div>

<div class="form-group mb-0 row align-items-center">
    <label class="col-8 col-form-label">Pack stress:</label>
    <div class="col-4 d-flex justify-content-end">
      <span class="switch switch-sm switch-primary">
        <label>
          <input type="checkbox" [checked]="land.packStress" name="selectStress" (change)="toggleStress()" />
          <span></span>
        </label>
      </span>
    </div>
</div>

<div class="form-group mb-0 row align-items-center">
    <label class="col-8 col-form-label">Midterm:</label>
    <div class="col-4 d-flex justify-content-end">
      <span class="switch switch-sm switch-primary">
        <label>
          <input type="checkbox" [checked]="land.midSeason" name="selectMidterm" (change)="toggleMidterm()" />
          <span></span>
        </label>
      </span>
    </div>
</div>