import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plots',
  templateUrl: './plots.component.html',
  styleUrls: ['./plots.component.scss']
})
export class PlotsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
